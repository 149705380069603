import React, { useState } from "react";
import EmptyState from "common/EmptyState/EmptyState";
import ManagePanel from "common/ManagePanel/ManagePanel";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Input } from "common/Input/Input";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import getTranslation from "utils/getTranslation";

interface StyleManagerCardProps {
	styles: string[];
	onAddStyle: (style: string) => void; // A function to handle addition of new styles
	onSave?: any;
	label: string;
	paddingY?: string;
	managePanelLabel: string;
	managePanelTitle: string;
	managePanelInputLabel: string;
}

const StyleManagerCard: React.FC<StyleManagerCardProps> = ({
	styles,
	onAddStyle,
	onSave,
	label,
	paddingY,
	managePanelLabel,
	managePanelTitle,
	managePanelInputLabel,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const initialColorState = { name_en: "", name_ru: "", name_tk: "" };
	const [newColor, setNewColor] = useState<any>(initialColorState);
	const [newStyles, setNewStyles] = useState(Array(10).fill(""));
	const { t } = useTranslation();
	const [isModalLoading, setIsModalLoading] = useState(false);

	const handleInputChange = (field: string | number, value: string) => {
		if (typeof field === "string") {
			setNewColor((prev: any) => ({ ...prev, [field]: value }));
		} else {
			const updatedStyles = [...newStyles];
			updatedStyles[field] = value;
			setNewStyles(updatedStyles);
		}
	};

	const handleAddAndSave = async () => {
		let payload: any;
		let endpoint: string;
		if (label === t("config.colors")) {
			payload = {
				name: {
					en: newColor.name_en,
					ru: newColor.name_ru,
					tk: newColor.name_tk,
				},
			};
			endpoint = "/color";
		} else {
			endpoint = label === "Size: Shoes" ? "/shoes-size" : "/clothing-size";
		}
		setIsModalLoading(true);

		try {
			if (label === t("config.colors")) {
				const response = await fetch(`${serviceConfig.apiUrl}${endpoint}`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(payload),
				});

				const result = await response.json();
				if (!response.ok) {
					throw new Error(result.message || "Failed to save");
				}

				// Append the full color object to the state
				toast.success(t("config.colorAddSuccess"));
				onAddStyle(result.data);
			} else {
				for (const style of newStyles) {
					if (style) {
						// Skip empty styles
						const response = await fetch(`${serviceConfig.apiUrl}${endpoint}`, {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ size: style }), // Send size as a string
						});

						const result = await response.json();
						if (!response.ok) {
							throw new Error(result.message || "Failed to save");
						}

						toast.success(t("config.styleAddSuccess"));
						onAddStyle(style);
					}
				}
			}

			setNewColor(initialColorState);
			setNewStyles(Array(10).fill(""));
			setIsOpen(false);
		} catch (error) {
			console.error("Error saving the item:", error);
			toast.error(t("config.saveError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	return (
		<div>
			<div className="grid max-w-[629px] gap-8 p-6 border rounded-lg shadow-xs border-strokeColor">
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p1" type="medium">
						{label}
					</P>

					<button
						className="grid gap-1 text-textColor grid-cols-maxTwo place-items-center"
						onClick={() => setIsOpen(true)}
					>
						<PlusIcon className="stroke-mainColor" />
						<P size="p2" type="medium" className="cursor-pointer">
							{t("config.add")}
						</P>
					</button>
				</div>
				<div
					className={`${
						styles.length > 0 ? "flex flex-wrap gap-3" : "place-content-stretch"
					}`}
				>
					{styles.length > 0 ? (
						styles.map((style: any, idx) => (
							<StyleManagerItem
								key={idx}
								label={
									label === t("config.colors")
										? getTranslation(style.name)
										: label === t("config.sizeShoes")
										? style.size
										: style.size
								}
								paddingY={paddingY}
							/>
						))
					) : (
						<EmptyState
							title={t("config.emptySizes")}
							className="place-content-stretch"
						/>
					)}
				</div>
			</div>

			{isOpen && (
				<ManagePanel
					onClose={() => setIsOpen(false)}
					onSave={handleAddAndSave}
					isOpen={isOpen}
					label={managePanelLabel}
					onDiscard={() => setIsOpen(false)}
					width="w-[515px]"
					isLoading={isModalLoading}
				>
					<div className="grid gap-6">
						<P size="p2" type="regular">
							{managePanelTitle}
						</P>
						<div className="grid gap-4">
							{label === t("config.colors") ? (
								// For colors, show the three inputs for different languages
								["en", "ru", "tk"].map((lang) => (
									<Input
										key={lang}
										withlabel={true}
										appearance="default"
										type="text"
										name={`color-${lang}`}
										label={`${managePanelInputLabel} (${lang.toUpperCase()})`}
										value={newColor[`name_${lang}`]}
										onChange={(e) =>
											handleInputChange(`name_${lang}`, e.target.value)
										}
									/>
								))
							) : (
								// For other labels, show the 10 input fields
								<div className="grid grid-cols-2 gap-4">
									{Array.from({ length: 10 }).map((_, index) => (
										<Input
											key={index}
											withlabel={true}
											appearance="default"
											type="text"
											name={`style-${index}`}
											label={`${managePanelInputLabel} №${index + 1}`}
											value={newStyles[index]}
											onChange={(e) => handleInputChange(index, e.target.value)}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</ManagePanel>
			)}
		</div>
	);
};

export default StyleManagerCard;
