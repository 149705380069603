/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as ArrowRightIcon } from "common/assets/icons/arrow-right.svg";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/chevron-down.svg";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Modal from "common/Modal/Modal";
import { serviceConfig } from "configs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import EditProductPanel from "components/EditProductPanel/EditProductPanel";
import getTranslation from "utils/getTranslation";

interface SubCategoryProductListProps {
	category?: any;
	data?: any;
	productPage?: boolean;
	searchData?: any;
}

type SubCategoryType = {
	_id: number;
	name: string;
	isActive: boolean;
	subSubCategory?: {
		_id: number;
		name: string;
		isActive: boolean;
	}[];
};

const SubCategoryProductList: React.FC<SubCategoryProductListProps> = ({
	category,
	productPage,
	data,
	searchData,
}) => {
	const { t } = useTranslation();
	const [isExpanded, setIsExpanded] = useState(true);
	const [isManagePanelOpen, setManagePanelOpen] = useState(false);
	const [currentEditItem, setCurrentEditItem] = useState<any>(null);
	const [currentEditType, setCurrentEditType] = useState<
		"Subcategory" | "Subsubcategory" | null
	>(null);
	const [uploadedImageSrc, setUploadedImageSrc] = useState<
		string | undefined
	>();
	const [isModalLoading, setIsModalLoading] = useState(false);

	const [rusValue, setRusValue] = useState("");
	const [engValue, setEngValue] = useState("");
	const [turkmenValue, setTurkmenValue] = useState("");

	const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
	const [currentProduct, setCurrentProduct] = useState(null);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isDeleteSubSubModalOpen, setIsDeleteSubSubModalOpen] = useState(false);

	let filteredProducts = [];
	console.log("SEARCH DATRA", searchData);

	if (productPage && searchData) {
		filteredProducts = searchData;
	} else {
		filteredProducts = data.filter((product: any) => {
			if (product.subCategoryId && product.subCategoryId === category._id) {
				return true;
			}
			// Check if the product has a subSubCategoryId and it matches the category's _id
			if (
				product.subSubCategoryId &&
				product.subSubCategoryId === category._id
			) {
				return true;
			}
			// If neither condition is met, exclude the product from the filtered list
			return false;
		});
	}

	console.log("Filtered Products:", filteredProducts);

	const resetInputValues = () => {
		setRusValue("");
		setEngValue("");
		setTurkmenValue("");
	};

	// if (!category || !Array.isArray(category.subSubCategory)) {
	// 	return null;
	// }

	// if (!category) {
	// 	return null;
	// }

	const handleEdit = (product: any) => {
		setCurrentProduct(product);
		setIsEditPanelOpen(true);
	};

	const handleSaveSuccess = (updatedProduct: any) => {
		setIsEditPanelOpen(false);
		toast.success("Product updated successfully");
		// Potentially update state here
	};

	const findParentCategoryId = (categories: any, subsubcategoryId: any) => {
		for (let cat of categories) {
			for (let subcat of cat.subCategories) {
				for (let subsubcat of subcat.subSubCategories) {
					if (subsubcat._id === subsubcategoryId) {
						return cat._id;
					}
				}
			}
		}
		return null;
	};

	const findParentSubCategoryId = (categories: any, subcategoryId: any) => {
		for (let cat of categories) {
			for (let subcat of cat.subCategories) {
				if (subcat._id === subcategoryId) {
					return cat._id;
				}
			}
		}
		return null;
	};

	const findSubcategoryId = (categories: any, subsubcategoryId: any) => {
		for (let cat of categories) {
			for (let subcat of cat.subCategories) {
				for (let subsubcat of subcat.subSubCategories) {
					if (subsubcat._id === subsubcategoryId) {
						return subcat._id;
					}
				}
			}
		}
		return null;
	};

	const handleDeleteSubcategory = async () => {
		if (!data || !category._id) {
			toast.error(t("categories.invalidDataError"));
			return;
		}

		const categoryId = findParentSubCategoryId(data, category._id);
		if (!categoryId) {
			toast.error(t("categories.parentCategoryNotFoundError"));
			return;
		}

		setIsModalLoading(true);

		const subcategoryId = category._id;
		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}`;

		try {
			const response = await fetch(url, {
				method: "DELETE",
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.subcategoryDeleteSuccess"));
				setIsDeleteModalOpen(false);
				window.location.reload();
			} else {
				toast.error(t("categories.subcategoryDeleteError"));
			}
		} catch (error) {
			toast.error(t("categories.subcategoryDeleteError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleEditSubcategory = async () => {
		if (!data || !category._id) {
			toast.error(t("categories.invalidDataError"));
			return;
		}
		if (!rusValue || !engValue || !turkmenValue) {
			toast.error(t("categories.validationError"));
			return;
		}

		setIsModalLoading(true);

		const categoryId = findParentSubCategoryId(data, category._id);
		if (!categoryId) {
			toast.error(t("categories.parentCategoryNotFoundError"));
			return;
		}

		const subcategoryId = category._id;
		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}`;

		try {
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: {
						en: engValue,
						ru: rusValue,
						tk: turkmenValue,
					},
				}),
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.editSuccess"));
				setManagePanelOpen(false);
				window.location.reload();
				setIsModalLoading(false);
			} else {
				toast.error(t("categories.editError"));
				console.error("Error updating subcategory:", responseData.message);
				setIsModalLoading(false);
			}
		} catch (error) {
			toast.error(t("categories.editError"));
			console.error("Failed to send request:", error);
			setIsModalLoading(false);
		}
	};

	const handleToggleSubcategory = async (status: boolean) => {
		if (!data || !category._id) {
			toast.error(t("categories.invalidDataError"));
			return;
		}

		const categoryId = findParentSubCategoryId(data, category._id);
		if (!categoryId) {
			toast.error(t("categories.parentCategoryNotFoundError"));
			return;
		}

		setIsModalLoading(true);

		const subcategoryId = category._id;
		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}`;
		const requestBody = {
			isActive: status,
		};

		try {
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.toggleStatusSuccess"));
				window.location.reload();
			} else {
				toast.error(t("categories.toggleStatusError"));
				console.error("Error updating subcategory:", responseData.message);
			}
		} catch (error) {
			toast.error(t("categories.toggleStatusError"));
			console.error("Failed to send request:", error);
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleEditSubSubcategory = async (subsub: any) => {
		const categoryId = findParentCategoryId(data, subsub._id);
		const subcategoryId = findSubcategoryId(data, subsub._id);

		if (!categoryId || !subcategoryId) {
			toast.error(t("categories.parentIDsNotFoundError"));
			return;
		}

		if (!engValue || !rusValue || !turkmenValue) {
			toast.error(t("categories.validationError"));
			return;
		}

		setIsModalLoading(true);

		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}/subsubcategories/${subsub._id}`;

		try {
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: {
						en: engValue,
						ru: rusValue,
						tk: turkmenValue,
					},
				}),
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.subSubcategoryEditSuccess"));
				setManagePanelOpen(false);
				window.location.reload();
			} else {
				toast.error(t("categories.subSubcategoryEditError"));
			}
		} catch (error) {
			toast.error(t("categories.subSubcategoryEditError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleToggleSubSubCategory = async (subsub: any, status: boolean) => {
		const categoryId = findParentCategoryId(data, subsub._id);
		const subcategoryId = findSubcategoryId(data, subsub._id);

		if (!categoryId || !subcategoryId) {
			toast.error(t("categories.parentIDsNotFoundError"));
			return;
		}

		setIsModalLoading(true);

		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}/subsubcategories/${subsub._id}`;
		const requestBody = {
			isActive: status,
		};

		try {
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.subSubcategoryStatusToggleSuccess"));
				window.location.reload();
			} else {
				toast.error(t("categories.subSubcategoryStatusToggleError"));
			}
		} catch (error) {
			toast.error(t("categories.subSubcategoryStatusToggleError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleDeleteSubSubcategory = async (subsub: any) => {
		const categoryId = findParentCategoryId(data, subsub._id);
		const subcategoryId = findSubcategoryId(data, subsub._id);

		if (!categoryId || !subcategoryId) {
			toast.error(t("categories.parentIDsNotFoundError"));
			return;
		}

		setIsModalLoading(true);

		const url = `${serviceConfig.apiUrl}/categories/${categoryId}/subcategories/${subcategoryId}/subsubcategories/${subsub._id}`;

		try {
			const response = await fetch(url, {
				method: "DELETE",
			});

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.subSubcategoryDeleteSuccess"));
				window.location.reload();
			} else {
				toast.error(t("categories.subSubcategoryDeleteError"));
			}
		} catch (error) {
			toast.error(t("categories.subSubcategoryDeleteError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleDeleteProduct = async (productId: any) => {
		setIsModalLoading(true);

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/products/${productId}`,
				{
					method: "DELETE",
				}
			);

			const responseData = await response.json();

			if (response.ok) {
				toast.success(t("categories.productDeleteSuccess"));
				window.location.reload();
			} else {
				toast.error(t("categories.productDeleteError"));
			}
		} catch (error) {
			toast.error(t("categories.productDeleteError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleToggleProductStatus = async (productId: any, newStatus: any) => {
		setIsModalLoading(true);

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/products/${productId}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ isActive: newStatus }),
				}
			);
			const responseData = await response.json();

			if (responseData.success) {
				toast.success(t("categories.productStatusToggleSuccess"));
				window.location.reload();
			} else {
				toast.error(t("categories.productStatusToggleError"));
			}
		} catch (error) {
			toast.error(t("categories.productStatusToggleError"));
		} finally {
			setIsModalLoading(false);
		}
	};

	const renderProducts = (products: any[], search = false) => {
		console.log("PRODUCTSSS", products);
		if (!products) return null; // Guard against undefined or null values

		return products.map((product, index) => {
			const firstColor = product.colors[0];
			const imageUrl =
				firstColor.images.length > 0
					? firstColor.images[0]
					: "default-image-url";
			const productName = getTranslation(product.name); // Assuming you want to display the English name

			const firstSize = firstColor.clothingSizes[0] || firstColor.shoesSizes[0];
			const price = firstSize ? firstSize.price : "N/A";
			return (
				<div
					key={index}
					className={`${
						!search && "ml-10"
					} px-4 py-3 bg-white border border-gray-200 rounded-lg shadow-sm`}
				>
					<div className="grid items-center gap-6 place-content-between grid-cols-frMax">
						<div className="grid items-center gap-6 grid-cols-maxFrMax">
							<img
								src={`${serviceConfig.apiUrl}${imageUrl}`}
								alt="product_image"
								className="object-cover w-10 h-10 rounded"
							/>
							<P
								size="p2"
								type="medium"
								className={`${
									product.isActive === true
										? "text-textColor"
										: "text-passiveTextColor"
								}`}
							>
								{productName}
							</P>
							<Input
								className="w-40 pl-20"
								price={true}
								appearance="default"
								type="text"
								name="order_date"
								disabled
								value={price}
							/>
						</div>
						<Actions
							isActive={product.isActive}
							onToggle={(status) =>
								handleToggleProductStatus(product._id, status)
							}
							onEdit={() => {
								console.log("Edit", product);
								setCurrentProduct(product);
								setIsEditPanelOpen(true);
							}}
							onDelete={() => handleDeleteProduct(product._id)}
						/>
					</div>
				</div>
			);
		});
	};

	return productPage ? (
		<>
			<div className="grid gap-2">
				{category && (
					<>
						<div
							className="flex items-center justify-between px-4 py-3 bg-white border rounded-lg shadow-sm cursor-pointer border-strokeColor"
							onClick={() => setIsExpanded(!isExpanded)}
						>
							<P size="p2" type="medium" className="cursor-pointer">
								{getTranslation(category.name)}
							</P>

							{isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
						</div>

						<AnimatePresence>
							{isExpanded && (
								<motion.div
									initial={{ height: 0, opacity: 0 }}
									animate={{ height: "auto", opacity: 1 }}
									exit={{ height: 0, opacity: 0 }}
									transition={{ duration: 0.3 }}
								>
									<div className="grid gap-2">
										{renderProducts(filteredProducts)}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
					</>
				)}

				{searchData && filteredProducts.length > 0 && (
					<div className="grid gap-2">
						{renderProducts(filteredProducts, true)}
					</div>
				)}
				{isEditPanelOpen && (
					<EditProductPanel
						isOpen={isEditPanelOpen}
						onClose={() => setIsEditPanelOpen(false)}
						product={currentProduct}
						onSaveSuccess={handleSaveSuccess}
					/>
				)}
			</div>
		</>
	) : (
		<>
			<div className="grid gap-2">
				<div
					className="flex items-center justify-between px-4 py-3 bg-white border rounded-lg shadow-sm cursor-pointer border-strokeColor"
					onClick={() => setIsExpanded(!isExpanded)}
				>
					<P size="p2" type="medium" className="cursor-pointer">
						{getTranslation(category.name)}
					</P>
					<div className="flex items-center gap-6">
						<Actions
							isActive={category.isActive}
							onToggle={(status) => handleToggleSubcategory(status)}
							onEdit={() => {
								console.log("Edit", category._id);
								setManagePanelOpen(true);
								setCurrentEditItem(category);
								setCurrentEditType("Subcategory");
								setRusValue(category.name.ru);
								setEngValue(category.name.en);
								setTurkmenValue(category.name.tk);
							}}
							onDelete={() => {
								console.log("Trying to delete:", currentEditItem);
								setCurrentEditItem(category);
								setIsDeleteModalOpen(true);
							}}
							onClick={(e: any) => e.stopPropagation()}
						/>
						{isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
					</div>
				</div>

				<Modal
					isOpen={isDeleteModalOpen}
					onClose={() => setIsDeleteModalOpen(false)}
					title="Are you sure you want to delete this subcategory?"
					onConfirm={handleDeleteSubcategory}
					onCancel={() => setIsDeleteModalOpen(false)}
					isLoading={isModalLoading}
				/>

				<AnimatePresence>
					{isExpanded && (
						<motion.div
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: "auto", opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
							transition={{ duration: 0.3 }}
						>
							<div className="grid gap-2">
								{category.subSubCategories.map((sub: any) => (
									<div
										key={sub._id}
										className="px-4 py-3 ml-10 bg-white border border-gray-200 rounded-lg shadow-sm"
									>
										<div className="flex items-center justify-between">
											<P size="p2" type="medium">
												{getTranslation(sub.name)}
											</P>
											<Actions
												isActive={sub.isActive}
												onToggle={(status) =>
													handleToggleSubSubCategory(sub, status)
												}
												onEdit={() => {
													setCurrentEditItem(sub);
													setRusValue(sub.name.ru);
													setEngValue(sub.name.en);
													setTurkmenValue(sub.name.tk);
													setCurrentEditType("Subsubcategory");
													setManagePanelOpen(true);
												}}
												onDelete={() => {
													setCurrentEditItem(sub);
													setIsDeleteSubSubModalOpen(true);
												}}
											/>
										</div>
										{sub.subsubcategory && (
											<div className="mt-4 ml-4">
												{sub.subsubcategory.map((subsub: any) => (
													<div key={subsub._id} className="mb-2">
														{subsub.name.en}
													</div>
												))}
											</div>
										)}
									</div>
								))}
							</div>
						</motion.div>
					)}
				</AnimatePresence>

				<Modal
					isOpen={isDeleteSubSubModalOpen}
					onClose={() => setIsDeleteSubSubModalOpen(false)}
					title="Are you sure you want to delete this sub-subcategory?"
					onConfirm={() => handleDeleteSubSubcategory(currentEditItem)}
					onCancel={() => setIsDeleteSubSubModalOpen(false)}
					isLoading={isModalLoading}
				/>

				<ManagePanel
					isOpen={isManagePanelOpen}
					onClose={() => {
						setManagePanelOpen(false);
						resetInputValues();
					}}
					label={`Edit ${currentEditType}`}
					onSave={() => {
						if (currentEditType === "Subcategory") {
							handleEditSubcategory();
						} else if (currentEditType === "Subsubcategory") {
							handleEditSubSubcategory(currentEditItem);
						}
						setManagePanelOpen(false);
					}}
					onDiscard={() => {
						console.log("Discard clicked");
						setManagePanelOpen(false);
					}}
					isLoading={isModalLoading}
				>
					{currentEditType === "Subcategory" ? (
						<div className="grid gap-6">
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="rus"
								label="Русский"
								placeholder="Например: Платья"
								value={rusValue}
								onChange={(e) => setRusValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="eng"
								label="English"
								placeholder="For example: Dresses"
								value={engValue}
								onChange={(e) => setEngValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="turkmen"
								label="Türkmen"
								placeholder="Meselem: Köýnekler"
								value={turkmenValue}
								onChange={(e) => setTurkmenValue(e.target.value)}
							/>
						</div>
					) : currentEditType === "Subsubcategory" ? (
						<div className="grid gap-6">
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="rus"
								label="Русский"
								placeholder="Например: Платья"
								value={rusValue}
								onChange={(e) => setRusValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="eng"
								label="English"
								placeholder="For example: Dresses"
								value={engValue}
								onChange={(e) => setEngValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="turkmen"
								label="Türkmen"
								placeholder="Meselem: Köýnekler"
								value={turkmenValue}
								onChange={(e) => setTurkmenValue(e.target.value)}
							/>
						</div>
					) : null}
				</ManagePanel>
			</div>
		</>
	);
};

export default SubCategoryProductList;
