import React, { useState } from "react";
import { SliderCardProps } from "./SliderCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const SliderCard: React.FC<SliderCardProps> = ({
	id,
	imageSrc,
	name,
	onEdit,
	onDelete,
	updateActiveStatus,
	active,
}) => {
	const handleToggle = (active: boolean) => {
		if (updateActiveStatus) {
			updateActiveStatus(id, active);
		}
	};

	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor h-max">
			<div className="grid gap-4 grid-cols-maxTwo place-items-center">
				<img
					src={`${serviceConfig.apiUrl}${imageSrc}`}
					alt={name}
					className="object-cover w-16 h-16 rounded-md"
				/>
				<P size="p2" type="medium">
					{name}
				</P>
			</div>
			<Actions
				isActive={active}
				onToggle={handleToggle}
				onEdit={() => onEdit && onEdit(id)}
				onDelete={() => onDelete && onDelete(id)}
			/>
		</div>
	);
};

export default SliderCard;
