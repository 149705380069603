import React from "react";
import { ImageUploadButton } from "./ImageUploadButton";
import { Image } from "./Image";
import { P } from "common/P/P";
import { useTranslation } from "react-i18next";

interface IPropsProductImageUpload {
	slider?: boolean;
	singleUpload?: boolean;
	onImageUpload?: (imageFile: any, variationIndex?: number) => void;
	onImageDelete?: (imageSrc: string, variationIndex?: number) => void; // New prop for deletion
	label?: string;
	variationIndex?: any;
	images?: { id: number; url: string }[];
}

const ProductImageUpload: React.FC<IPropsProductImageUpload> = ({
	slider,
	singleUpload = false,
	onImageUpload,
	onImageDelete,
	label,
	variationIndex,
	images = [],
}) => {
	console.log("INDEX", variationIndex);
	const { t } = useTranslation();

	console.log("Images", images);

	const handleUpload = (imageFile: any) => {
		console.log("Uploading image for color index:", variationIndex);
		if (onImageUpload) {
			onImageUpload(imageFile, variationIndex);
		}
	};
	// Similar update for handleDeleteImage
	const handleDeleteImage = (imageId: any) => {
		if (onImageDelete) {
			onImageDelete(imageId, variationIndex);
		}
	};

	const getOrdinalNumber = (n: number): string => {
		const s = ["th", "st", "nd", "rd"];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	return (
		<div className="grid">
			{(!singleUpload || (singleUpload && images.length === 0)) && (
				<ImageUploadButton onChange={handleUpload} />
			)}
			{slider ? (
				images.map((src, idx) => (
					<Image
						key={idx}
						src={src.url}
						slider={slider}
						onDelete={() => handleDeleteImage(src)}
					/>
				))
			) : (
				<div className="grid gap-2 mt-4 grid-cols-maxTwo">
					{images.map((src, idx) => {
						console.log(`Rendering image: ${src.url}`);
						const imageLabel = `${getOrdinalNumber(idx + 1)} ${
							label ? label : t("others.picture")
						}`;
						return (
							<div key={idx} className="grid gap-2">
								<P size="p3" type="medium">
									{imageLabel}
								</P>
								<Image
									src={src.url}
									onDelete={() => handleDeleteImage(src.id)}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default ProductImageUpload;
