import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "navigation/PrivateRoute";

import { UserRoutes, routeConstants } from "navigation";

import LoginPage from "pages/LoginPage/LoginPage";

export const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path={routeConstants.login.route} element={<LoginPage />} />
			<Route path="*" element={<PrivateRoute element={<UserRoutes />} />} />
		</Routes>
	);
};
