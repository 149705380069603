import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { SearchInput } from "common/Search/Search";
import Pagination from "components/Pagination/Pagination";
import DatePicker from "components/DatePicker/DatePicker";
import OrdersTable from "components/OrderTable/OrdersTable";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { serviceConfig } from "configs";
import dateFormatter from "utils/dateFormatter";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";
import getTranslation from "utils/getTranslation";

const OrdersPage: React.FC = () => {
	const { t } = useTranslation();
	const [orders, setOrders] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;
	const totalPages = Math.ceil(orders.length / itemsPerPage);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);

	useEffect(() => {
		// Fetch orders on component mount
		fetchOrders();
	}, []);

	const fetchOrders = async () => {
		setIsLoading(true);
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/orders`);
			const data = await response.json();
			setOrders(data.data);
			setIsLoading(false);
		} catch (error) {
			console.error("Failed to fetch orders:", error);
			setIsLoading(false);
		}
	};

	const searchOrders = async (query: string) => {
		setIsLoading(true);
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/orders/search?q=${query}`
			);
			const data = await response.json();
			setOrders(data.data);
			setIsLoading(false);
		} catch (error) {
			console.error("Failed to search orders:", error);
			setIsLoading(false);
		}
	};

	const filterOrdersByDate = async (date: string) => {
		setIsLoading(true);
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/orders/date?date=${date}`
			);
			const data = await response.json();
			setOrders(data.data);
			setIsLoading(false);
		} catch (error) {
			console.error("Failed to filter orders by date:", error);
			setIsLoading(false);
		}
	};

	const currentOrders = orders.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const [selectedOrder, setSelectedOrder] = useState<any>(null);
	const [isPanelOpen, setIsPanelOpen] = useState(false);

	const [orderId, setOrderId] = useState("");
	const [orderedDate, setOrderDate] = useState("");
	const [products, setProducts] = useState([]);
	const [customerName, setCustomerName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [note, setNote] = useState("");
	const [orderStatus, setOrderStatus] = useState("");
	const [totalPrice, setTotalPrice] = useState(0);

	const handleRowClick = (order: any) => {
		const orderDateFormatted = dateFormatter(order.orderDate);
		// Update the states with the selected order's data
		setOrderId(order._id);
		setOrderDate(orderDateFormatted);
		setProducts(order.products);
		setCustomerName(order.shippingAddress.fullName);
		setPhoneNumber(order.phoneNumber);
		setAddress(order.shippingAddress.address1);
		setNote(order.notes);
		setOrderStatus(order.orderStatus);
		setTotalPrice(order.orderSummary.total);

		setSelectedOrder(order);
		setIsPanelOpen(true);
	};

	const closePanel = () => {
		setIsPanelOpen(false);
		setSelectedOrder(null);
	};

	const saveChanges = async () => {
		setIsModalLoading(true);
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/orders/${selectedOrder._id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						orderStatus: orderStatus, // This is the updated status
					}),
				}
			);

			const data = await response.json();

			if (data.success) {
				// Close the panel after updating
				toast.success(data.message);
				closePanel();
				setIsModalLoading(false);
				// Refresh orders to reflect the changes
				fetchOrders();
			} else {
				toast.error(data.message);

				setIsModalLoading(false);
			}
		} catch (error) {
			console.error("Error updating order:", error);
			setIsModalLoading(false);
		}
	};

	const discardChanges = () => {
		// Implement any logic for discarding changes if needed
		closePanel();
	};

	const ORDER_STATUSES: any = {
		Waiting: "text-[#FE9A04]",
		Preparing: "text-[#BF310A]",
		Delivered: "text-[#2DAA6F]",
		Cancelled: "text-[#F21414]",
		Returned: "text-[#101828]",
	};

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					{t("orders.title")}
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					{t("others.subTitlePages")}
				</P>
			</div>
			<div className="grid gap-6">
				<div className="grid gap-6 mt-8 grid-cols-[60%_35%]">
					<SearchInput
						placeholder={t("placeholders.search")}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								const searchTerm = e.currentTarget?.value || "";
								if (searchTerm.length === 0) {
									fetchOrders();
								} else {
									searchOrders(searchTerm);
								}
							}
						}}
					/>

					<DatePicker
						onChange={(selectedDate) => filterOrdersByDate(selectedDate)}
					/>
				</div>
				<OrdersTable orders={currentOrders} onRowClick={handleRowClick} />
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={setCurrentPage}
				/>
				{isPanelOpen && (
					<ManagePanel
						isOpen={isPanelOpen}
						onClose={closePanel}
						label={t("orders.orderDetails")}
						onSave={saveChanges}
						onDiscard={discardChanges}
						isLoading={isModalLoading}
					>
						{selectedOrder && (
							<div className="grid gap-4">
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="order_date"
									label={t("orders.orderDate")}
									disabled
									value={orderedDate}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="customer_name"
									label={t("orders.customerName")}
									disabled
									value={customerName}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="order_id"
									label={t("orders.id")}
									disabled
									value={orderId}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="phone_number"
									label={t("orders.phone")}
									disabled
									value={phoneNumber}
								/>
								{selectedOrder?.products?.map((product: any, index: number) => (
									<div className="grid gap-1">
										<P size="p3" type="medium" className="text-textColor">
											{`${t("orders.product")} №${index + 1}`}
										</P>
										<div key={index} className="grid gap-4 grid-cols-[70%_30%]">
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_name_${index}`}
												disabled
												value={getTranslation(product.product.name)}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_price_${index}`}
												disabled
												value={`${product.totalPrice} ${serviceConfig.currencySign}`}
											/>

											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_color_${index}`}
												disabled
												value={getTranslation(
													product.selectedColorData?.colorName
												)}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_size_${index}`}
												disabled
												value={`${product.selectedSizeData?.size}`}
											/>
										</div>
									</div>
								))}

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="total_price"
									label={t("orders.totalPrice")}
									disabled
									value={totalPrice}
								/>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="address"
									label={t("orders.address")}
									disabled
									value={address}
								/>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="note"
									label={t("orders.note")}
									disabled
									value={note}
								/>

								<Select
									onSelect={(value) => setOrderStatus(value)}
									defaultSelectLabel={orderStatus}
									label={t("orders.status")}
								>
									{Object.keys(ORDER_STATUSES).map((status) => (
										<SelectItem
											key={status}
											value={status}
											className={ORDER_STATUSES[status]}
										>
											{status}
										</SelectItem>
									))}
								</Select>
							</div>
						)}
					</ManagePanel>
				)}
			</div>
		</div>
	);
};

export default OrdersPage;
