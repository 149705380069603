/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "common/Button/Button";
import { Input } from "common/Input/Input";

import ImageUpload from "common/Image/ImageUpload";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import CategoriesCard from "components/CategoriesCard/CategoriesCard";
import { SearchInput } from "common/Search/Search";
import SubCategoryProductList from "components/SubCategoryProductList/SubCategoryProductList";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { serviceConfig } from "configs";
import Checkbox from "common/Checkbox/Checkbox";
import ColorSelection from "components/ColorSelection/ColorSelection";
import { useTranslation } from "react-i18next";
import ClothingSizeSelection from "components/ClothingSizeSelection/ClothingSizeSelection";
import ShoesSizeSelection from "components/ShoesSizeSelection/ShoesSizeSelection";
import ProductImageUpload from "common/Image/ProductImageUpload";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";

const ProductsPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState<"all" | "active" | "inactive">("all");
	const [categories, setCategories] = useState<any>([]);
	const [colors, setColors] = useState<any>([]);
	const [clothingSizes, setClothingSizes] = useState<any>([]);
	const [shoeSizes, setShoeSizes] = useState<any>([]);
	const [selectedClothingSize, setSelectedClothingSize] = useState(null);
	const [selectedShoeSize, setSelectedShoeSize] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [productInfo, setProductInfo] = useState<any>({
		rusValue: "",
		engValue: "",
		turkmenValue: "",
		descriptions: {
			ru: "",
			eng: "",
			turkmen: "",
		},
		features: {
			ru: "",
			eng: "",
			turkmen: "",
		},
		sizes: [
			{
				priceValue: "",
				discountValue: "",
				quantityValue: "",
			},
		],
	});

	const [productVariations, setProductVariations] = useState<any[]>([
		{
			color: null,
			images: [],
			imageFiles: [],
			sizes: [
				{
					size: null,
					type: "clothing", // default type set here
					priceValue: "",
					discountValue: "",
					quantityValue: "",
				},
			],
		},
	]);

	useEffect(() => {
		productVariations.forEach((variation, index) => {
			console.log(`ImageSrc for variation ${index}:`, variation.imageSrc);
		});
	}, [productVariations]);

	const [selections, setSelections] = useState<any>({
		category: null,
		subCategory: null,
		subSubCategory: null,
		isChecked: false,
		colors: [],
		sizes: [null],
	});
	const [currentStepNumber, setCurrentStepNumber] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const [uploadedImageSrc, setUploadedImageSrc] = useState("");
	const [selectedSubcategory, setSelectedSubcategory] = useState<any>(null);
	const [selectedSubSubcategory, setSelectedSubSubcategory] =
		useState<any>(null);
	const [lastSelected, setLastSelected] = useState<string>("");
	const [currentVariationIndex, setCurrentVariationIndex] = useState(0);
	const [products, setProducts] = useState([]);
	const [isLoadingProducts, setIsLoadingProducts] = useState(false);
	const [searchDataProducts, setSearchDataProducts] = useState([]);

	console.log("SEARCH PRODUCT", searchDataProducts);
	console.log("Selected PRODUCT", products);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				let response = await fetch(`${serviceConfig.apiUrl}/categories`);
				let data = await response.json();
				setCategories(data.data);

				response = await fetch(`${serviceConfig.apiUrl}/color`);
				data = await response.json();
				setColors(data.data);

				response = await fetch(`${serviceConfig.apiUrl}/clothing-size`);
				data = await response.json();
				setClothingSizes(data.data);

				response = await fetch(`${serviceConfig.apiUrl}/shoes-size`);
				data = await response.json();
				setShoeSizes(data.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchProducts = async () => {
			setIsLoadingProducts(true);
			try {
				const response = await fetch(
					`${serviceConfig.apiUrl}/products?isAdmin=true`
				);
				const data = await response.json();
				setProducts(data.data); // Assuming 'data' is the array of products
			} catch (error) {
				console.error("Error fetching products:", error);
			}
			setIsLoadingProducts(false);
		};

		fetchProducts();
	}, []);
	interface StepOrder {
		initial: number;
		selectColor: number;
		uploadImage: number;
		selectSize: number;
		inputPrice: number;
		finalStep: number;
	}

	const stepOrder: StepOrder = {
		initial: 1,
		selectColor: 2,
		uploadImage: 3,
		selectSize: 4,
		inputPrice: 5,
		finalStep: 6,
	};

	const handleInputChange = useCallback((name: any, value: any) => {
		setProductInfo((prev: any) => {
			const keys = name.split(".");
			if (keys.length > 1) {
				const [firstKey, secondKey] = keys;
				return {
					...prev,
					[firstKey]: {
						...prev[firstKey],
						[secondKey]: value,
					},
				};
			} else {
				return { ...prev, [name]: value };
			}
		});
	}, []);

	const handleSelectionChange = useCallback(
		(name: any, value: any, id = null) => {
			setSelections((prev: any) => {
				// Handle the special case for a checkbox
				if (name === "isChecked") {
					return { ...prev, isChecked: value };
				}

				// Reset the other size type when one size type is selected
				let updatedSelections = { ...prev, [name]: value };
				if (name === "selectedClothingSize") {
					updatedSelections.selectedShoeSize = null;
				} else if (name === "selectedShoeSize") {
					updatedSelections.selectedClothingSize = null;
				}

				// Add ID to the selections if provided
				if (id !== null) {
					const idName = `${name}Id`;
					updatedSelections[idName] = id;
				}

				return updatedSelections;
			});
		},
		[]
	);

	const createSizeObject = (size: any) => {
		const price = parseFloat(size.priceValue);
		const discountPercentage = parseFloat(size.discountValue);
		const discountPrice =
			discountPercentage > 0
				? (price - (price * discountPercentage) / 100).toFixed(2)
				: null;

		return {
			sizeId: size.size,
			price: price,
			discounted: discountPercentage > 0,
			discount_price: discountPrice ? parseFloat(discountPrice) : null,
			discount_percentage: discountPercentage > 0 ? discountPercentage : null,
			stock: parseInt(size.quantityValue),
		};
	};

	const resetFormAndSelections = () => {
		// Reset product info
		setProductInfo({
			rusValue: "",
			engValue: "",
			turkmenValue: "",
			descriptions: {
				ru: "",
				eng: "",
				turkmen: "",
			},
			features: {
				ru: "",
				eng: "",
				turkmen: "",
			},
			sizes: [
				{
					priceValue: "",
					discountValue: "",
					quantityValue: "",
				},
			],
		});

		// Reset product variations
		setProductVariations([
			{
				color: null,
				images: [],
				imageFiles: [],
				sizes: [
					{
						size: null,
						type: "clothing",
						priceValue: "",
						discountValue: "",
						quantityValue: "",
					},
				],
			},
		]);

		// Reset selections
		setSelections({
			category: null,
			subCategory: null,
			subSubCategory: null,
			isChecked: false,
			colors: [],
			sizes: [null],
		});
	};

	const handleSaveProduct = useCallback(async () => {
		const { rusValue, engValue, turkmenValue, descriptions, features } =
			productInfo;
		const { selectedCategory, selectedSubCategory } = selections;
		if (
			!rusValue ||
			!engValue ||
			!turkmenValue ||
			!descriptions.ru ||
			!descriptions.eng ||
			!descriptions.turkmen ||
			!features.ru ||
			!features.eng ||
			!features.turkmen ||
			!selectedCategory ||
			!selectedSubCategory
		) {
			toast.error(t("categories.fillAllFieldsError"));
			return;
		}
		setIsModalLoading(true);
		const formData = new FormData();

		// Create a productData object to include all non-file properties
		const productDataObject = {
			name: {
				tk: productInfo.turkmenValue,
				ru: productInfo.rusValue,
				en: productInfo.engValue,
			},
			description: {
				tk: productInfo.descriptions.turkmen,
				ru: productInfo.descriptions.ru,
				en: productInfo.descriptions.eng,
			},
			features: {
				tk: productInfo.features.turkmen,
				ru: productInfo.features.ru,
				en: productInfo.features.eng,
			},
			delivery_info: {
				tk: "Şäheriň içinde eltip bermek sargyt güni amala aşyrylýar. Welaýatlara eltip bermek 1-2 günüň içinde amala aşyrylýar.",
				ru: "Доставка по городу осуществляется в день заказа. Доставка в велаяты в течение 1-2 дней.",
				en: "Delivery within the city is carried out on the day of order. Delivery to velayats within 1-2 days.",
			},
			return_policy: {
				tk: "Satyn alnan harytlary çalyşmak satyn alnan senesinden 3 günüň dowamynda mümkindir (önüm bellikler bilen asyl ýagdaýynda bolmaly). Satyn alnan zatlary yzyna gaýtaryp bolmaz.",
				ru: "Обмен купленного товара возможен в течение 3-х суток со дня покупки (товар должен быть в исходном состоянии с биркой). Купленный товар возврату не подлежит.",
				en: "Exchange of purchased goods is possible within 3 days from the date of purchase (the product must be in its original condition with tags). Purchased items can not be returned.",
			},
			categoryId: selections.selectedCategoryId,
			subCategoryId: selections.selectedSubCategoryId,
			subSubCategoryId: selections.selectedSubSubCategoryId,
			bestSelling: selections.isChecked,
			colors: productVariations.map((variation) => ({
				colorId: variation.color,
				clothingSizes: variation.sizes
					.filter((size: any) => size.type === "clothing")
					.map(createSizeObject),
				shoesSizes: variation.sizes
					.filter((size: any) => size.type === "shoe")
					.map(createSizeObject),
			})),
		};

		// Append the stringify productData object
		console.log("DATA", productVariations);
		formData.append("productData", JSON.stringify(productDataObject));

		// Append files for each color variation
		productVariations.forEach((variation, index) => {
			if (Array.isArray(variation.imageFiles)) {
				variation.imageFiles.forEach((fileObj: any, fileIndex: any) => {
					// Ensure you're appending the 'file' property of the fileObj
					if (fileObj && fileObj.file instanceof Blob) {
						formData.append(
							`colors[${index}][images][${fileIndex}]`,
							fileObj.file,
							fileObj.file.name
						);
					}
				});
			}
		});

		try {
			const response = await fetch(`${serviceConfig.apiUrl}/products`, {
				method: "POST",
				body: formData,
			});
			const data = await response.json();
			if (data.success) {
				toast.success(t("products.productSuccessSave"));
				setIsOpen(false);
				resetFormAndSelections();
				window.location.reload();
			} else {
				toast.error(t("products.productErrorSave"));
			}
		} catch (error) {
			console.error("Error saving product:", error);
			toast.error(t("products.productFailure"));
		} finally {
			setIsModalLoading(false);
		}
	}, [productInfo, selections, productVariations]);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const handleSubcategorySelection = (subcategory: any) => {
		setSelectedSubcategory(subcategory);
		setLastSelected("subcategory");
	};

	const handleSubSubcategorySelection = (subSubcategory: any) => {
		setSelectedSubSubcategory(subSubcategory);
		setLastSelected("subSubcategory");
	};

	const filteredData =
		filter === "all"
			? categories
			: categories.filter((category: any) =>
					filter === "active" ? category.active : !category.active
			  );

	const handleDiscard = () => {
		console.log("Discarded");
		handleClose();
	};

	const selectedCategory = categories.find(
		(category: any) => category.name.en === selections.selectedCategory
	);

	const selectedSubCategory = selectedCategory?.subCategories.find(
		(subCategory: any) => subCategory.name.en === selections.selectedSubCategory
	);

	const hasSubSubCategories = selectedSubCategory?.subSubCategories
		? selectedSubCategory.subSubCategories.some(
				(subSub: any) => subSub.isActive
		  )
		: false;

	const setCurrentStep = (stepName: string) => {
		if (stepName in stepOrder) {
			setCurrentStepNumber(stepOrder[stepName as keyof StepOrder]);
		} else {
			console.error("Invalid stepName:", stepName);
		}
	};

	useEffect(() => {
		const isInitialStepComplete = () => {
			const { rusValue, engValue, turkmenValue, descriptions, features } =
				productInfo;
			return (
				rusValue &&
				engValue &&
				turkmenValue &&
				descriptions.ru &&
				descriptions.eng &&
				descriptions.turkmen &&
				features.ru &&
				features.eng &&
				features.turkmen &&
				selections.selectedCategory &&
				selections.selectedSubCategory
			);
		};

		if (isInitialStepComplete() && currentStepNumber === stepOrder.initial) {
			setCurrentStepNumber(stepOrder.selectColor);
		}
	}, [productInfo, selections, currentStepNumber]);

	const determineNextStep = (currentStep: any) => {
		const steps = [
			"initial",
			"selectColor",
			"uploadImage",
			"selectSize",
			"inputPrice",
			"finalStep",
		];
		const currentStepIndex = steps.indexOf(currentStep);
		const nextStepIndex = currentStepIndex + 1;
		return steps[nextStepIndex] || "finalStep";
	};

	const checkStepCompletion = (step: any) => {
		switch (step) {
			case "initial":
				return (
					productInfo.rusValue &&
					productInfo.engValue &&
					productInfo.turkmenValue
				);
			case "selectColor":
				return selections.color && selections.color;

			default:
				return false;
		}
	};

	useEffect(() => {
		if (checkStepCompletion(currentStepNumber)) {
			const nextStep = determineNextStep(currentStepNumber);
			setCurrentStep(nextStep);
		}
	}, [
		currentStepNumber,
		productInfo.rusValue,
		productInfo.engValue,
		productInfo.turkmenValue,
		selections.color,
	]);

	useEffect(() => {
		if (selections.color && !uploadedImageSrc) {
			setCurrentStep("uploadImage");
		} else if (uploadedImageSrc) {
			setCurrentStep("selectSize");
		}
	}, [selections.color, uploadedImageSrc]);

	useEffect(() => {
		if (
			productInfo.priceValue &&
			productInfo.discountValue &&
			productInfo.quantityValue
		) {
			setCurrentStep("finalStep");
		}
	}, [
		productInfo.priceValue,
		productInfo.discountValue,
		productInfo.quantityValue,
	]);

	const addNewProductSize = (
		variationIndex: number,
		defaultType = "clothing"
	) => {
		setProductVariations((prevVariations) => {
			const newVariations = [...prevVariations];
			newVariations[variationIndex].sizes.push({
				size: null,
				type: defaultType, // default type set here
				priceValue: "",
				discountValue: "",
				quantityValue: "",
			});
			return newVariations;
		});
	};

	const addNewProductColor = () => {
		setProductVariations((prevVariations) => [
			...prevVariations,
			{
				color: null,
				images: [],
				sizes: [
					{
						size: null,
						priceValue: "",
						discountValue: "",
						quantityValue: "",
					},
				],
			},
		]);
		// Automatically setting the next index for image upload
		setCurrentVariationIndex((prevIndex) => prevIndex + 1);
	};

	const formatDiscountPlaceholder = (price: any, discount: any) => {
		// Extract the numeric part from the discount string
		const discountPercentage = parseFloat(discount);

		// Check if price or discount is not provided, or if discountPercentage is NaN
		if (!price || isNaN(discountPercentage)) {
			return "N/A";
		}

		const discountAmount = (parseFloat(price) * discountPercentage) / 100;
		return (parseFloat(price) - discountAmount).toFixed(2);
	};

	const handleImageUpload = (imageFile: any) => {
		const imageUrl = URL.createObjectURL(imageFile);
		const imageId = Date.now(); // Using a timestamp as a unique identifier

		setProductVariations((prevVariations) =>
			prevVariations.map((variation, idx) =>
				idx === currentVariationIndex
					? {
							...variation,
							images: [...variation.images, { id: imageId, url: imageUrl }],
							imageFiles: Array.isArray(variation.imageFiles)
								? [...variation.imageFiles, { id: imageId, file: imageFile }]
								: [{ id: imageId, file: imageFile }],
					  }
					: variation
			)
		);
	};

	const handleImageDelete = (imageId: any, variationIndex: any) => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, idx) => {
				if (idx === variationIndex) {
					const updatedImages = variation.images.filter(
						(image: any) => image.id !== imageId
					);
					const updatedImageFiles = variation.imageFiles.filter(
						(file: any) => file.id !== imageId
					);

					// Revoke the URL for the deleted image
					const imageToDelete = variation.images.find(
						(image: any) => image.id === imageId
					);
					if (imageToDelete) {
						URL.revokeObjectURL(imageToDelete.url);
					}

					return {
						...variation,
						images: updatedImages,
						imageFiles: updatedImageFiles,
					};
				}
				return variation;
			})
		);
	};

	const searchProducts = async (query: string) => {
		setIsLoading(true);
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/products/search?q=${query}&isAdmin=true`
			);
			const data = await response.json();
			setSearchDataProducts(data.data);
			setIsLoading(false);
		} catch (error) {
			console.error("Failed to search orders:", error);
			setIsLoading(false);
		}
	};

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						{t("products.title")}
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						{t("others.subTitlePages")}
					</P>
				</div>
				<Button
					appearance="black"
					icon={<PlusIcon />}
					onClick={handleOpen}
					className="h-fit"
				>
					{t("products.addProduct")}
				</Button>
			</div>

			<div className="grid gap-6 mt-8 grid-cols-maxFr">
				<CategoriesCard
					data={filteredData}
					setSelectedSubcategory={handleSubcategorySelection}
					setSelectedSubSubcategory={handleSubSubcategorySelection}
					productPage={true}
				/>
				<div className="grid gap-4 h-fit">
					<SearchInput
						placeholder={t("placeholders.search")}
						onKeyDown={async (e) => {
							if (e.key === "Enter") {
								const searchTerm = e.currentTarget.value || "";
								if (searchTerm.length === 0) {
									// Optionally reset the search result or show default data
									// For example, you might want to refetch or simply clear the search results
									setSearchDataProducts([]); // Assuming you want to clear search results
									// Add any other logic here if you want to fetch all products again
								} else {
									await searchProducts(searchTerm);
								}
							}
						}}
					/>

					{searchDataProducts.length > 0 ? (
						<SubCategoryProductList
							productPage={true}
							searchData={searchDataProducts}
						/>
					) : lastSelected === "subSubcategory" ? (
						<SubCategoryProductList
							category={selectedSubSubcategory}
							productPage={true}
							data={products}
						/>
					) : (
						lastSelected === "subcategory" && (
							<SubCategoryProductList
								category={selectedSubcategory}
								productPage={true}
								data={products}
							/>
						)
					)}
				</div>
			</div>

			<ManagePanel
				isOpen={isOpen}
				onClose={() => {
					handleClose();
					setCurrentStep("initial");
				}}
				label={t("products.title")}
				onSave={handleSaveProduct}
				onDiscard={handleDiscard}
				isLoading={isModalLoading}
			>
				{/* Step 1: Initial inputs */}
				{currentStepNumber >= stepOrder.initial && (
					<div className="grid gap-6">
						<div className="grid gap-6">
							<div className="grid gap-4">
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="rusValue"
									label={t("products.name")}
									placeholder="Русский: Кимоно"
									value={productInfo.rusValue}
									onChange={(e) =>
										handleInputChange(e.target.name, e.target.value)
									}
								/>
								<Input
									appearance="default"
									type="text"
									name="engValue"
									placeholder="English: Kimono"
									value={productInfo.engValue}
									onChange={(e) =>
										handleInputChange(e.target.name, e.target.value)
									}
								/>
								<Input
									appearance="default"
									type="text"
									name="turkmenValue"
									placeholder="Türkmen: Kimono"
									value={productInfo.turkmenValue}
									onChange={(e) =>
										handleInputChange(e.target.name, e.target.value)
									}
								/>
							</div>
							<Select
								onSelect={(value) => {
									const category = categories.find(
										(item: any) => item.name.en === value
									);
									handleSelectionChange(
										"selectedCategory",
										value,
										category?._id
									);
									handleSelectionChange("selectedSubCategory", null);
									handleSelectionChange("selectedSubSubCategory", null);
								}}
								defaultSelectLabel={
									selections.selectedCategory || t("categories.selectCategory")
								}
								label={t("categories.category")}
							>
								{categories.map((item: any) => (
									<SelectItem value={item.name.en} key={item._id}>
										{item.name.en}
									</SelectItem>
								))}
							</Select>
							<Select
								key={selections.selectedCategory || "defaultKey"}
								onSelect={(value) => {
									const selectedCategory = categories.find(
										(item: any) => item.name.en === selections.selectedCategory
									);
									const subCategory = selectedCategory?.subCategories.find(
										(sub: any) => sub.name.en === value
									);
									handleSelectionChange(
										"selectedSubCategory",
										value,
										subCategory?._id
									);
								}}
								defaultSelectLabel={
									selections.selectedSubCategory ||
									t("categories.selectSubCategory")
								}
								label={t("categories.subcategory")}
								disabled={!selections.selectedCategory}
							>
								{selections.selectedCategory
									? categories
											.find(
												(item: any) =>
													item.name.en === selections.selectedCategory
											)
											?.subCategories.filter((sub: any) => sub.isActive)
											.map((sub: any) => (
												<SelectItem value={sub.name.en} key={sub._id}>
													{sub.name.en}
												</SelectItem>
											))
									: null}
							</Select>

							{hasSubSubCategories && selections.selectedSubCategory && (
								<Select
									key={selections.selectedSubCategory || "defaultSubKey"}
									onSelect={(value) => {
										const selectedSubCategory =
											selectedCategory?.subCategories.find(
												(sub: any) =>
													sub.name.en === selections.selectedSubCategory
											);
										const subSubCategory =
											selectedSubCategory?.subSubCategories.find(
												(subsub: any) => subsub.name.en === value
											);
										handleSelectionChange(
											"selectedSubSubCategory",
											value,
											subSubCategory?._id
										);
									}}
									defaultSelectLabel={
										selections.selectedSubSubCategory ||
										t("categories.selectSubSubCategory")
									}
									label={t("categories.subSubCategory")}
									disabled={!selections.selectedSubCategory}
								>
									{selectedSubCategory?.subSubCategories
										.filter((subsub: any) => subsub.isActive)
										.map((subsub: any) => (
											<SelectItem value={subsub.name.en} key={subsub._id}>
												{subsub.name.en}
											</SelectItem>
										))}
								</Select>
							)}
						</div>
						<Checkbox
							id="Bestselling"
							label={t("products.bestselling")}
							checked={selections.isChecked}
							onChange={(checked) =>
								handleSelectionChange("isChecked", checked)
							}
						/>

						<div className="grid gap-4">
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="descriptions.ru"
								label="Descriptions"
								placeholder="Описание продукта"
								value={productInfo.descriptions.ru}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>

							<Input
								appearance="default"
								type="text"
								name="descriptions.eng"
								placeholder="English: Product description"
								value={productInfo.descriptions.eng}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>

							<Input
								appearance="default"
								type="text"
								name="descriptions.turkmen"
								placeholder="Türkmen: Harydyň düşündirilişi"
								value={productInfo.descriptions.turkmen}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>
						</div>

						<div className="grid gap-4">
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="features.ru"
								label={t("products.features")}
								placeholder="Русский: Особенности продукта"
								value={productInfo.features.ru}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>
							<Input
								appearance="default"
								type="text"
								name="features.eng"
								placeholder="English: Product features"
								value={productInfo.features.eng}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>
							<Input
								appearance="default"
								type="text"
								name="features.turkmen"
								placeholder="Türkmen: Harydyň aýratynlygy"
								value={productInfo.features.turkmen}
								onChange={(e) =>
									handleInputChange(e.target.name, e.target.value)
								}
							/>
						</div>
					</div>
				)}

				{currentStepNumber >= stepOrder.selectColor &&
					productVariations.map((variation, variationIndex) => (
						<div key={variationIndex} className="grid grid-cols-1 gap-4">
							{/* Color selection input */}
							<ColorSelection
								selectedColor={variation.color}
								setSelectedColor={(color: any) => {
									const updatedVariations = [...productVariations];
									updatedVariations[variationIndex].color = color;
									setProductVariations(updatedVariations);
								}}
								onColorSelected={(colorId: any) => {}}
								colorData={colors}
							/>

							{/* Image upload input */}

							<ProductImageUpload
								images={variation.images}
								onImageUpload={(imageFile) => {
									handleImageUpload(imageFile);
								}}
								onImageDelete={(src) => handleImageDelete(src, variationIndex)}
								variationIndex={variationIndex}
								label={`Picture for Color ${variationIndex + 1}`}
							/>

							{/* Sizes for the current variation */}
							{variation.sizes.map((size: any, sizeIndex: any) => (
								<div key={sizeIndex} className="grid grid-cols-1 gap-4">
									{/* Size selection input */}
									<ClothingSizeSelection
										selectedSize={size.size}
										setSelectedSize={(sizeId: any) => {
											let updatedVariations = [...productVariations];

											updatedVariations[variationIndex] = {
												...updatedVariations[variationIndex],
												sizes: updatedVariations[variationIndex].sizes.map(
													(size: any, idx: number) =>
														idx === sizeIndex
															? { ...size, size: sizeId, type: "clothing" }
															: size
												),
											};

											setProductVariations(updatedVariations);
										}}
										sizeData={clothingSizes}
										onSizeSelected={() => {
											// Additional logic if needed when a size is selected
										}}
									/>

									<ShoesSizeSelection
										selectedSize={size.size}
										setSelectedSize={(sizeId: any) => {
											let updatedVariations = [...productVariations];

											updatedVariations[variationIndex] = {
												...updatedVariations[variationIndex],
												sizes: updatedVariations[variationIndex].sizes.map(
													(innerSize: any, idx: number) =>
														idx === sizeIndex
															? { ...innerSize, size: sizeId, type: "shoe" }
															: innerSize
												),
											};

											setProductVariations(updatedVariations);
										}}
										sizeData={shoeSizes}
										onSizeSelected={() => {
											// Additional logic if needed when a size is selected
										}}
									/>

									<div className="grid gap-6 grid-cols-maxTwo">
										<Input
											price={true}
											className="w-40 pl-20"
											appearance="default"
											type="text"
											name={`priceValue_${variationIndex}_${sizeIndex}`}
											label={t("products.price")}
											value={size.priceValue}
											onChange={(e) => {
												const updatedVariations = [...productVariations];
												const newPrice = e.target.value;
												const discountValue =
													updatedVariations[variationIndex].sizes[sizeIndex]
														.discountValue;

												updatedVariations[variationIndex].sizes[
													sizeIndex
												].priceValue = newPrice;

												updatedVariations[variationIndex].sizes[
													sizeIndex
												].discountedPricePlaceholder =
													formatDiscountPlaceholder(newPrice, discountValue);

												setProductVariations(updatedVariations);
											}}
											placeholder="0"
										/>
										<Input
											discount={true}
											appearance="default"
											type="text"
											name={`discountValue_${variationIndex}_${sizeIndex}`}
											label={t("products.discount")}
											discountedPrice={
												`${formatDiscountPlaceholder(
													size.priceValue,
													size.discountValue
												)} ${serviceConfig.currencySign}` ||
												`N/A ${serviceConfig.currencySign}`
											}
											placeholder="20%"
											value={size.discountValue}
											onChange={(e) => {
												const updatedVariations = [...productVariations];
												const newDiscount = e.target.value;
												const priceValue =
													updatedVariations[variationIndex].sizes[sizeIndex]
														.priceValue;

												updatedVariations[variationIndex].sizes[
													sizeIndex
												].discountValue = newDiscount;

												updatedVariations[variationIndex].sizes[
													sizeIndex
												].discountedPricePlaceholder =
													formatDiscountPlaceholder(priceValue, newDiscount);

												setProductVariations(updatedVariations);
											}}
										/>
									</div>
									<Input
										withlabel={true}
										appearance="default"
										type="text"
										name={`quantityValue_${variationIndex}_${sizeIndex}`}
										label={t("products.quantity")}
										placeholder={t("placeholders.stock")}
										value={size.quantityValue}
										onChange={(e) => {
											const updatedVariations = [...productVariations];
											updatedVariations[variationIndex].sizes[
												sizeIndex
											].quantityValue = e.target.value;
											setProductVariations(updatedVariations);
										}}
									/>
								</div>
							))}

							{/* Button to add a new size for the current variation */}

							<button
								onClick={() => addNewProductSize(variationIndex)}
								className="grid gap-1 cursor-pointer grid-cols-maxTwo place-items-center place-content-end"
							>
								<PlusIcon className="stroke-black" />
								<P size="p2" type="regular" className="cursor-pointer">
									{t("products.addSize")}
								</P>
							</button>

							{/* Button to add a new color variation */}
							{variationIndex === productVariations.length - 1 && (
								<Button
									onClick={addNewProductColor}
									appearance="black"
									width="full"
									icon={<PlusIcon />}
								>
									{t("products.addNewColor")}
								</Button>
							)}
						</div>
					))}
			</ManagePanel>
		</div>
	);
};

export default ProductsPage;
