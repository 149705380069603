import React, { useState, ChangeEvent } from "react";
import "./DatePicker.css";

interface DatePickerProps {
	onChange: (date: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ onChange }) => {
	const [date, setDate] = useState<string>("");

	const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedDate = event.target.value;
		setDate(selectedDate);
		if (onChange) {
			onChange(selectedDate);
		}
	};

	return (
		<input
			type="date"
			value={date}
			onChange={handleDateChange}
			className="w-full h-full px-4 py-3 transition-all duration-300 border rounded-lg shadow-xs appearance-none input border-strokeColor hover:border-mainColor focus:border-mainColor"
		/>
	);
};

export default DatePicker;
