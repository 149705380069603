import React from "react";
import "./Actions.css";

import { ReactComponent as EditIcon } from "common/assets/icons/edit-pencil.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash.svg";
import { ActionsProps } from "./Actions.props";

const Actions: React.FC<ActionsProps> = ({
	isActive,
	onToggle,
	onEdit,
	onDelete,
	onClick,
}) => {
	return (
		<div
			className="grid grid-flow-col gap-6 auto-cols-max"
			onClick={(e) => {
				if (onClick) {
					onClick(e);
				}
			}}
		>
			{onToggle && (
				<label className="switch">
					<input
						type="checkbox"
						checked={isActive}
						onChange={() => onToggle && onToggle(!isActive)}
					/>
					<span className="slider round"></span>
				</label>
			)}
			{onEdit && (
				<EditIcon
					onClick={onEdit}
					className="cursor-pointer stroke-passiveTextColor"
				/>
			)}
			{onDelete && (
				<DeleteIcon
					onClick={onDelete}
					className="cursor-pointer stroke-passiveTextColor"
				/>
			)}
		</div>
	);
};

export default Actions;
