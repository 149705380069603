import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CategoryItemProps } from "./CategoryItem.props";
import { ReactComponent as ArrowRightIcon } from "common/assets/icons/arrow-right.svg";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/chevron-down.svg";
import { P } from "common/P/P";
import Actions from "common/Actions/Actions";
import { Input } from "common/Input/Input";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { serviceConfig } from "configs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

export const CategoryItem: React.FC<CategoryItemProps> = ({
	category,
	onSelectSubcategory,
	onSelectSubSubcategory,
	productPage,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isExpandedSub, setIsExpandedSub] = useState(false);
	const [isManagePanelOpen, setManagePanelOpen] = useState(false);
	const [rusValue, setRusValue] = useState("");
	const [engValue, setEngValue] = useState("");
	const [turkmenValue, setTurkmenValue] = useState("");
	const [currentCategoryId, setCurrentCategoryId] = useState<any>(null);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [expandedSubcategoryIds, setExpandedSubcategoryIds] = useState<
		Set<string>
	>(new Set());

	const { t } = useTranslation();

	const toggleSubcategoryExpansion = (subcategoryId: string) => {
		setExpandedSubcategoryIds((prevIds) => {
			const newIds = new Set(prevIds);
			if (newIds.has(subcategoryId)) {
				newIds.delete(subcategoryId);
			} else {
				newIds.add(subcategoryId);
			}
			return newIds;
		});
	};

	const resetInputValues = () => {
		setRusValue("");
		setEngValue("");
		setTurkmenValue("");
	};

	const handleSave = async () => {
		if (!rusValue || !engValue || !turkmenValue) {
			toast.error(t("categories.validationError"));
			return;
		}

		setIsModalLoading(true);
		if (currentCategoryId) {
			try {
				const response = await fetch(
					`${serviceConfig.apiUrl}/categories/${currentCategoryId}`,
					{
						method: "PUT",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							name: {
								en: engValue,
								ru: rusValue,
								tk: turkmenValue,
							},
						}),
					}
				);

				const result = await response.json();

				if (response.ok) {
					toast.success(t("categories.updateSuccess"));
					window.location.reload();
				} else {
					toast.error(t("categories.updateError"));
					console.error("Failed to update category:", result.message);
				}
			} catch (error) {
				toast.error(t("categories.updateError"));
				console.error("Error updating category:", error);
			} finally {
				setIsModalLoading(false);
			}
		}

		setManagePanelOpen(false);
	};

	return (
		<>
			{productPage ? (
				<>
					<div
						className="flex items-center justify-between px-4 py-3 my-2 border-b cursor-pointer border-strokeColor hover:bg-hoverColor"
						onClick={() => setIsExpanded(!isExpanded)}
					>
						<div className="flex items-center">
							<div
								className={`w-2 h-2 rounded-full ${
									category.isActive ? "bg-green-500" : "bg-gray-400"
								}`}
							/>
							<P size="p3" type="medium" className="ml-4">
								{getTranslation(category.name)}
							</P>
						</div>
						{isExpanded ? (
							<ArrowDownIcon className="w-6 h-6" />
						) : (
							<ArrowRightIcon />
						)}
					</div>
					<AnimatePresence>
						{isExpanded && (
							<motion.div
								initial={{ height: 0, opacity: 0, scale: 0.9 }}
								animate={{
									height: "auto",
									opacity: 1,
									scale: 1,
									transition: {
										duration: 0.3,
									},
								}}
								exit={{
									height: 0,
									opacity: 0,
									scale: 0.9,
									transition: {
										duration: 0.3,
									},
								}}
							>
								{category.subCategories.map((sub, idx) => (
									<div className="ml-5" key={idx}>
										<div
											className="flex items-center justify-between px-4 py-3 border-b cursor-pointer border-strokeColor hover:bg-hoverColor"
											onClick={() => {
												if (
													sub.subSubCategories &&
													sub.subSubCategories.length > 0
												) {
													toggleSubcategoryExpansion(sub._id);
												} else {
													onSelectSubcategory(sub);
												}
											}}
										>
											<div className="grid gap-2 grid-cols-maxTwo place-items-center">
												<div
													className={`w-2 h-2 rounded-full ${
														sub.isActive ? "bg-green-500" : "bg-gray-400"
													}`}
												/>
												<P size="p3" type="medium" className="ml-4">
													{getTranslation(sub.name)}
												</P>
											</div>
											{sub.subSubCategories &&
												sub.subSubCategories.length > 0 &&
												(expandedSubcategoryIds.has(sub._id) ? (
													<ArrowDownIcon className="w-6 h-6" />
												) : (
													<ArrowRightIcon />
												))}
										</div>
										<AnimatePresence>
											{expandedSubcategoryIds.has(sub._id) && (
												<motion.div
													initial={{ height: 0, opacity: 0, scale: 0.9 }}
													animate={{
														height: "auto",
														opacity: 1,
														scale: 1,
														transition: {
															duration: 0.3,
														},
													}}
													exit={{
														height: 0,
														opacity: 0,
														scale: 0.9,
														transition: {
															duration: 0.3,
														},
													}}
												>
													{sub.subSubCategories.map((subSub: any, idx: any) => (
														<div className="ml-5" key={idx}>
															<div
																className="flex items-center px-4 py-3 border-b cursor-pointer border-strokeColor hover:bg-hoverColor"
																onClick={() => {
																	onSelectSubSubcategory(subSub);
																}}
															>
																<div
																	className={`w-2 h-2 rounded-full ${
																		subSub.isActive
																			? "bg-green-500"
																			: "bg-gray-400"
																	}`}
																/>
																<P size="p3" type="medium" className="ml-4">
																	{getTranslation(subSub.name)}
																</P>
															</div>
														</div>
													))}
												</motion.div>
											)}
										</AnimatePresence>
									</div>
								))}
							</motion.div>
						)}
					</AnimatePresence>
				</>
			) : (
				<>
					<div
						className="flex items-center justify-between px-4 py-3 my-2 border-b cursor-pointer border-strokeColor hover:bg-hoverColor"
						onClick={() => setIsExpanded(!isExpanded)}
					>
						<div className="flex items-center">
							<div
								className={`w-2 h-2 rounded-full ${
									category.isActive ? "bg-green-500" : "bg-gray-400"
								}`}
							/>
							<P size="p3" type="medium" className="ml-4">
								{getTranslation(category.name)}
							</P>
						</div>
						<div className="grid gap-2 grid-cols-maxTwo">
							<Actions
								onEdit={() => {
									setRusValue(category.name.ru);
									setEngValue(category.name.en);
									setTurkmenValue(category.name.tk);
									setCurrentCategoryId(category._id);
									setManagePanelOpen(true);
								}}
								onClick={(e: any) => e.stopPropagation()}
							/>
							{isExpanded ? (
								<ArrowDownIcon className="w-6 h-6" />
							) : (
								<ArrowRightIcon />
							)}
						</div>
					</div>
					<AnimatePresence>
						{isExpanded && (
							<motion.div
								initial={{ height: 0, opacity: 0, scale: 0.9 }}
								animate={{
									height: "auto",
									opacity: 1,
									scale: 1,
									transition: {
										duration: 0.3,
									},
								}}
								exit={{
									height: 0,
									opacity: 0,
									scale: 0.9,
									transition: {
										duration: 0.3,
									},
								}}
							>
								{category.subCategories.map((sub, idx) => (
									<div className="ml-5" key={idx}>
										<div
											className="flex items-center px-4 py-3 border-b cursor-pointer border-strokeColor hover:bg-hoverColor"
											onClick={() => onSelectSubcategory(sub)}
										>
											<div
												className={`w-2 h-2 rounded-full ${
													sub.isActive ? "bg-green-500" : "bg-gray-400"
												}`}
											/>
											<P size="p3" type="medium" className="ml-4">
												{getTranslation(sub.name)}
											</P>
										</div>
									</div>
								))}
							</motion.div>
						)}
					</AnimatePresence>
					<ManagePanel
						isOpen={isManagePanelOpen}
						onClose={() => {
							setManagePanelOpen(false);
							resetInputValues();
						}}
						label={`Edit Category`}
						onSave={handleSave}
						onDiscard={() => {
							setManagePanelOpen(false);
						}}
						isLoading={isModalLoading}
					>
						<div className="grid gap-6">
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="rus"
								label="Русский"
								placeholder="Например: Women"
								value={rusValue}
								onChange={(e) => setRusValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="eng"
								label="English"
								placeholder="For example: Women"
								value={engValue}
								onChange={(e) => setEngValue(e.target.value)}
							/>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name="turkmen"
								label="Türkmen"
								placeholder="Meselem: Women"
								value={turkmenValue}
								onChange={(e) => setTurkmenValue(e.target.value)}
							/>
						</div>
					</ManagePanel>
				</>
			)}
		</>
	);
};
