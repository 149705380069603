import React, { useEffect, useState } from "react";
import { Input } from "common/Input/Input";
import { Button } from "common/Button/Button";
import { P } from "common/P/P";
import { ContactCardProps } from "./ContactCard.props";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";

const ContactCard: React.FC<ContactCardProps> = ({
	label,
	onSave,
	companyData,
	isLoading,
}) => {
	const { t } = useTranslation();
	const [shopName, setShopName] = useState(companyData?.section || "");
	const [addressEn, setAddressEn] = useState(companyData?.address_en || "");
	const [addressRu, setAddressRu] = useState(companyData?.address_en || "");
	const [addressTkm, setAddressTkm] = useState(companyData?.address_en || "");
	const [phone, setPhone] = useState(companyData?.phoneNumber || "");
	const [instagram, setInstagram] = useState(companyData?.instagramLink || "");
	const [tiktok, setTiktok] = useState(companyData?.tiktokLink || "");

	useEffect(() => {
		setShopName(companyData?.section || "");
		setAddressEn(companyData?.address_en || "");
		setAddressRu(companyData?.address_ru || "");
		setAddressTkm(companyData?.address_tkm || "");
		setPhone(companyData?.phoneNumber || "");
		setInstagram(companyData?.instagramLink || "");
		setTiktok(companyData?.tiktokLink || "");
	}, [companyData]);

	const handleSubmit = () => {
		if (
			!shopName ||
			!addressEn ||
			!addressRu ||
			!addressTkm ||
			!phone ||
			!instagram ||
			!tiktok
		) {
			toast.error(t("categories.fillAllFieldsError"));
			return;
		}
		const data = {
			section: label.split(" ")[1], // "Tellale Man" => "Man"
			name: shopName,
			address_en: addressEn,
			address_ru: addressRu,
			address_tkm: addressTkm,
			phoneNumber: phone,
			instagramLink: instagram,
			tiktokLink: tiktok,
		};

		// Filter out the empty values
		const filteredData = Object.fromEntries(
			Object.entries(data).filter(([key, value]) => value !== "")
		);

		onSave(filteredData);
	};

	return (
		<div className="grid max-w-[581px] gap-6 p-6 border rounded-lg shadow-xs border-strokeColor">
			<P size="p1" type="medium">
				{label}
			</P>
			<div className="grid gap-4">
				<Input
					withlabel={true}
					appearance="default"
					type="text"
					name="shop_name"
					label={t("contact.shopName")}
					placeholder={t("placeholders.shopName")}
					value={shopName}
					onChange={(e) => setShopName(e.target.value)}
				/>
				<Input
					withlabel={true}
					appearance="default"
					type="text"
					name="address_en"
					label={t("contact.address")}
					placeholder={t("placeholders.addressEn")}
					value={addressEn}
					onChange={(e) => setAddressEn(e.target.value)}
				/>
				<Input
					appearance="default"
					type="text"
					name="address_ru"
					placeholder={t("placeholders.addressRu")}
					value={addressRu}
					onChange={(e) => setAddressRu(e.target.value)}
				/>
				<Input
					appearance="default"
					type="text"
					name="address_tkm"
					placeholder={t("placeholders.addressTkm")}
					value={addressTkm}
					onChange={(e) => setAddressTkm(e.target.value)}
				/>
				<Input
					withlabel={true}
					appearance="default"
					type="text"
					name="phone"
					label={t("contact.phoneNumber")}
					placeholder="+993"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
				/>
				<div className="grid grid-cols-2 gap-4">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="instagram"
						label="Instagram"
						placeholder="@"
						value={instagram}
						onChange={(e) => setInstagram(e.target.value)}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="tiktok"
						label="Tiktok"
						placeholder="@"
						value={tiktok}
						onChange={(e) => setTiktok(e.target.value)}
					/>
				</div>
			</div>

			{isLoading ? (
				<div className="w-max place-self-end">
					<Spinner inModal={true} />
				</div>
			) : (
				<Button
					onClick={handleSubmit}
					appearance="black"
					className="place-self-end"
				>
					{t("others.save")}
				</Button>
			)}
		</div>
	);
};

export default ContactCard;
