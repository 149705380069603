import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";

export enum FilterStatus {
	All,
	Active,
	Inactive,
}

interface TabFilterProps {
	onFilterChange: (filter: FilterStatus) => void;
}

const TabFilter: React.FC<TabFilterProps> = ({ onFilterChange }) => {
	const [filter, setFilter] = useState(FilterStatus.All);
	const controls = useAnimation();

	const handleFilterClick = (status: FilterStatus, index: number) => {
		setFilter(status);
		onFilterChange(status);
		controls.start({ x: index * 100 });
	};

	return (
		<div className="relative grid grid-cols-3 bg-gray-100 border border-gray-200 rounded-lg">
			<motion.div
				className="absolute top-0 mt-1 bg-white rounded-lg shadow-sm h-9 left-1"
				style={{ width: "88px" }}
				initial={{ x: 0 }}
				animate={controls}
				transition={{ duration: 0.3, type: "spring", stiffness: 50 }}
			></motion.div>
			{Object.values(FilterStatus).map((status, index) => {
				if (isNaN(Number(status))) {
					return (
						<button
							key={index}
							onClick={() =>
								handleFilterClick(
									FilterStatus[status as keyof typeof FilterStatus],
									index
								)
							}
							className="relative z-auto px-4 py-2 m-1 text-sm font-semibold rounded-lg text-passiveTextColor"
						>
							{status}
						</button>
					);
				}
				return null;
			})}
		</div>
	);
};

export default TabFilter;
