import { Navigate, Route, Routes } from "react-router-dom";

import { routeConstants } from "navigation";

import ContactPage from "pages/ContactPage/ContactPage";
import ConfigPage from "pages/ConfigPage/ConfigPage";
import OrdersPage from "pages/OrdersPage/OrdersPage";
import CategoriesPage from "pages/CategoriesPage/CategoriesPage";
import ProductsPage from "pages/ProductsPage/ProductsPage";
import SlidersPage from "pages/SlidersPage/SlidersPage";

export const UserRoutes = () => {
	return (
		<Routes>
			<Route path={routeConstants.slider.route} element={<SlidersPage />} />
			<Route path={routeConstants.contact.route} element={<ContactPage />} />
			<Route path={routeConstants.config.route} element={<ConfigPage />} />
			<Route path={routeConstants.order.route} element={<OrdersPage />} />
			<Route
				path={routeConstants.category.route}
				element={<CategoriesPage />}
			/>
			<Route path={routeConstants.product.route} element={<ProductsPage />} />

			<Route path="*" element={<Navigate to={routeConstants.root.route} />} />
		</Routes>
	);
};
