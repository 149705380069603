import { route_prefix } from "configs";

export const routeConstants = {
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	slider: {
		route: `${route_prefix}/sliders`,
		name: "Sldiers",
	},
	category: {
		route: `${route_prefix}/categories`,
		name: "Categories",
	},
	product: {
		route: `${route_prefix}/products`,
		name: "Products",
	},
	order: {
		route: `${route_prefix}/orders`,
		name: "Orders",
	},
	contact: {
		route: `${route_prefix}/contact-us`,
		name: "Contact us",
	},
	config: {
		route: `${route_prefix}/configs`,
		name: "Configs",
	},
	login: {
		route: `${route_prefix}/login`,
		name: "Login",
	},
	logout: {
		route: `${route_prefix}/logout`,
		name: "Logout",
	},
};
