import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import CurrencyCard from "components/CurrencyCard/CurrencyCard";
import StyleManagerCard from "components/StyleManagerCard/StyleManagerCard";
import { serviceConfig } from "configs";
import Spinner from "modules/loaders/Spinner/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ConfigPage: React.FC = () => {
	const [shoes, setShoes] = useState<string[]>([]);
	const [clothes, setClothes] = useState<string[]>([]);
	const [colors, setColors] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();

	// const handleSaveCurrency = (data: any) => {
	// 	// API call or other logic for Tellale Man
	// 	console.log("Data for Man: ", data);
	// };

	const handleAddShoes = (newSize: string) => {
		setShoes((prevShoes) => [...prevShoes, newSize]);
	};

	const handleAddClothes = (newSize: string) => {
		setClothes((prevClothes) => [...prevClothes, newSize]);
	};

	const handleAddColors = (newColorObject: any) => {
		setColors((prevColors) => [...prevColors, newColorObject]);
	};

	useEffect(() => {
		// Fetch initial data from the backend
		const fetchInitialData = async () => {
			setIsLoading(true);
			try {
				const [shoesResponse, clothesResponse, colorsResponse] =
					await Promise.all([
						fetch(`${serviceConfig.apiUrl}/shoes-size`),
						fetch(`${serviceConfig.apiUrl}/clothing-size`),
						fetch(`${serviceConfig.apiUrl}/color`),
					]);

				const shoesData = await shoesResponse.json();
				const clothesData = await clothesResponse.json();
				const colorsData = await colorsResponse.json();

				setShoes(shoesData.data);
				setClothes(clothesData.data);
				setColors(colorsData.data);
				setIsLoading(false);
			} catch (error) {
				console.error("Failed to fetch initial data:", error);
				setIsLoading(false);
			}
		};

		fetchInitialData();
	}, []);

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					{t("config.title")}
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					{t("others.subTitlePages")}
				</P>
			</div>
			<div className="grid grid-cols-1 gap-6 mt-8">
				{/* <CurrencyCard label="Currency converter" onSave={handleSaveCurrency} /> */}
				<StyleManagerCard
					styles={shoes}
					onAddStyle={handleAddShoes}
					label={t("config.sizeShoes")}
					managePanelLabel={t("config.addShoesSize")}
					managePanelTitle={t("config.shoesTitle")}
					managePanelInputLabel={t("config.size")}
				/>
				<StyleManagerCard
					styles={clothes}
					onAddStyle={handleAddClothes}
					label={t("config.sizeClothes")}
					managePanelLabel={t("config.addClothesSize")}
					managePanelTitle={t("config.clothesTitle")}
					managePanelInputLabel={t("config.size")}
				/>
				<StyleManagerCard
					styles={colors}
					onAddStyle={handleAddColors}
					label={t("config.colors")}
					paddingY="py-2"
					managePanelLabel={t("config.addColors")}
					managePanelTitle={t("config.colorTitle")}
					managePanelInputLabel={t("config.color")}
				/>
			</div>
		</div>
	);
};

export default ConfigPage;
