import React, { useEffect, useState } from "react";
import { Button } from "common/Button/Button";
import { Input } from "common/Input/Input";
import test_image from "common/assets/images/test_image.png";
import ImageUpload from "common/Image/ImageUpload";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { ReactComponent as EmptyIcon } from "common/assets/icons/slider_empty.svg";
import EmptyState from "common/EmptyState/EmptyState";
import SliderCard from "components/SliderCard/SliderCard";
import { serviceConfig } from "configs";
import Modal from "common/Modal/Modal";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";
import getTranslation from "utils/getTranslation";

const SlidersPage: React.FC = (): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [uploadedImageSrc, setUploadedImageSrc] = useState<
		string | undefined
	>();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [sliderToDelete, setSliderToDelete] = useState<number | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);

	const [sliders, setSliders] = useState<any>([]);
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameTkm, setNameTkm] = useState("");
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const { t } = useTranslation();

	const handleSliderEdit = (sliderDetails: any) => {
		setNameEn(sliderDetails.name_en);
		setNameRu(sliderDetails.name_ru);
		setNameTkm(sliderDetails.name_tkm);
		setSliderToDelete(sliderDetails._id); // set the ID for editing
		setIsEditing(true);
		setIsOpen(true);
	};

	const handleSliderDeleteRequest = (sliderId: any) => {
		setSliderToDelete(sliderId);
		setShowDeleteConfirmationModal(true);
	};

	// This useEffect hook fetches the sliders when the component mounts
	useEffect(() => {
		setIsLoading(true);
		fetch(`${serviceConfig.apiUrl}/slider`)
			.then((response) => response.json())
			.then((data) => {
				setSliders(data.data);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching the sliders:", error);
				setIsLoading(false);
			});
	}, []); //

	const handleSave = () => {
		if (!nameEn || !nameRu || !nameTkm) {
			toast.error(t("sliders.validationError"));
			return;
		}
		setIsModalLoading(true);

		if (isEditing) {
			handleEditSave({
				id: sliderToDelete,
				name_en: nameEn,
				name_ru: nameRu,
				name_tkm: nameTkm,
				image: uploadedImageSrc,
			});
		} else {
			if (uploadedImageSrc) {
				const formData = new FormData();
				formData.append("name_en", nameEn);
				formData.append("name_ru", nameRu);
				formData.append("name_tkm", nameTkm);
				formData.append("image", uploadedImageSrc);

				fetch(`${serviceConfig.apiUrl}/slider`, {
					method: "POST",
					body: formData,
				})
					.then((response) => response.json())
					.then((data) => {
						if (Array.isArray(sliders)) {
							setSliders((prev: any) => [...prev, data.data]);
						} else {
							setSliders([data.data]);
						}
						toast.success(t("sliders.saveSuccess"));
						setIsOpen(false);
						setIsModalLoading(false);
						setUploadedImageSrc(undefined);
						window.location.reload();
					})

					.catch((error) => {
						console.error("Error saving the new slider:", error);
						setIsModalLoading(false);
						toast.error(t("sliders.saveError"));
					});
			}
		}
	};

	const handleEditSave = (sliderDetails: any) => {
		if (
			!sliderDetails.name_en ||
			!sliderDetails.name_ru ||
			!sliderDetails.name_tkm
		) {
			toast.error(t("sliders.validationError"));
			return;
		}
		setIsModalLoading(true);
		const formData = new FormData();
		formData.append("name_en", sliderDetails.name_en);
		formData.append("name_ru", sliderDetails.name_ru);
		formData.append("name_tkm", sliderDetails.name_tkm);
		if (sliderDetails.image) {
			formData.append("image", sliderDetails.image);
		}

		fetch(`${serviceConfig.apiUrl}/slider/${sliderDetails.id}`, {
			method: "PUT",
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				const updatedSliders = sliders.map((slider: any) => {
					if (slider._id === sliderDetails.id) return data.data;
					return slider;
				});
				toast.success(t("sliders.updateSuccess"));
				setSliders(updatedSliders);
				setIsModalLoading(false);
				setIsOpen(false);
				setIsEditing(false);
				window.location.reload();
			})
			.catch((error) => {
				console.error("Error updating the slider:", error);
				setIsModalLoading(false);
				toast.error(t("sliders.updateError"));
			});
	};

	const handleDiscard = () => {
		setIsOpen(false);
		// Reset any other state if necessary
	};

	const handleSliderDeleteConfirm = () => {
		setIsModalLoading(true);
		if (sliderToDelete) {
			fetch(`${serviceConfig.apiUrl}/slider/${sliderToDelete}`, {
				method: "DELETE",
			})
				.then((response) => response.json())
				.then((data) => {
					setSliders((prev: any) =>
						prev.filter((slider: any) => slider._id !== sliderToDelete)
					);
					setShowDeleteConfirmationModal(false);
					setIsModalLoading(false);
					toast.success(t("sliders.deleteSuccess"));
				})
				.catch((error) => {
					console.error("Error deleting the slider:", error);
					setIsModalLoading(false);
					toast.error(t("sliders.deleteError"));
				});
		}
	};

	const updateActiveStatus = (id: string | number, isActive: boolean) => {
		setIsLoading(true);
		fetch(`${serviceConfig.apiUrl}/slider/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ isActive }),
		})
			.then((response) => response.json())
			.then((data) => {
				const updatedSliders = sliders.map((slider: any) => {
					if (slider._id === id) {
						return {
							...slider,
							isActive: isActive,
						};
					}
					return slider;
				});
				setSliders(updatedSliders);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error updating the active status:", error);
				setIsLoading(false);
			});
	};

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						{t("sliders.title")}
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						{t("others.subTitlePages")}
					</P>
				</div>
				<Button appearance="black" icon={<PlusIcon />} onClick={handleOpen}>
					{t("sliders.addBanner")}
				</Button>
			</div>

			<div className="grid gap-4 mt-8 min-h-[80vh] auto-rows-max">
				{sliders.length > 0 ? (
					sliders.map((slider: any) => (
						<SliderCard
							key={slider._id}
							id={slider._id}
							imageSrc={slider.image}
							name={getTranslation(slider, true)}
							active={slider.isActive}
							onEdit={() => handleSliderEdit(slider)}
							onDelete={handleSliderDeleteRequest}
							updateActiveStatus={updateActiveStatus}
						/>
					))
				) : (
					<div className="grid place-content-center place-items-center w-full h-[95%]">
						<EmptyState
							icon={<EmptyIcon />}
							title={t("sliders.emptyTitle")}
							description={t("sliders.emptyDescription")}
						/>
					</div>
				)}
			</div>

			<ManagePanel
				isOpen={isOpen}
				onClose={handleClose}
				label={isEditing ? t("sliders.editBanner") : t("sliders.addBanner")}
				onSave={handleSave}
				onDiscard={handleDiscard}
				isLoading={isModalLoading}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name_en"
						label={t("sliders.enBannerDesc")}
						placeholder="Name of banner in English"
						value={nameEn}
						onChange={(e) => setNameEn(e.target.value)}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name_ru"
						label={t("sliders.ruBannerDesc")}
						placeholder="Название баннера по Русский"
						value={nameRu}
						onChange={(e) => setNameRu(e.target.value)}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name_tkm"
						label={t("sliders.tkBannerDesc")}
						placeholder="Türkmençe banneriň ady"
						value={nameTkm}
						onChange={(e) => setNameTkm(e.target.value)}
					/>
					<ImageUpload
						singleUpload={true}
						onImageUpload={setUploadedImageSrc}
					/>
				</div>
			</ManagePanel>

			{showDeleteConfirmationModal && (
				<Modal
					isOpen={showDeleteConfirmationModal}
					onClose={() => setShowDeleteConfirmationModal(false)}
					title={t("others.confirmDelete")}
					onConfirm={handleSliderDeleteConfirm}
					onCancel={() => setShowDeleteConfirmationModal(false)}
					isLoading={isModalLoading}
				/>
			)}
		</div>
	);
};

export default SlidersPage;
