import React from "react";
import { CheckboxProps } from "./Checkbox.props";
import styles from "./Checkbox.module.css"; // Make sure your path is correct
import { P } from "common/P/P";

const Checkbox: React.FC<CheckboxProps> = ({
	id,
	label,
	checked,
	onChange,
}) => {
	return (
		<div className="grid gap-2 grid-cols-maxTwo place-items-center">
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={(e) => onChange(e.target.checked)}
				className={`${styles.checkbox} h-4 w-4 cursor-pointer checked:border-transparent focus:ring-0 rounded-[4px]`}
			/>
			<label htmlFor={id} className="cursor-pointer text-passiveTextColor">
				<P size="p2" type="medium">
					{label}
				</P>
			</label>
		</div>
	);
};

export default Checkbox;
