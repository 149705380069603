import React from "react";
import { PaginationProps } from "./Pagination.props";
import { useTranslation } from "react-i18next";

const Pagination: React.FC<PaginationProps> = ({
	currentPage,
	totalPages,
	onPageChange,
}) => {
	const { t } = useTranslation();
	const START_END_PAGES = 3;

	const getStartRange = () => {
		return Math.max(1, currentPage - 1);
	};

	const getEndRange = () => {
		return Math.min(totalPages, currentPage + 1);
	};

	const isPrevDisabled = currentPage === 1;
	const isNextDisabled = currentPage === totalPages;

	return (
		<div className="flex gap-1 place-self-end">
			<button
				onClick={() => onPageChange(currentPage - 1)}
				disabled={isPrevDisabled}
				className={`px-3 py-2 rounded-lg bg-hoverColor text-passiveTextColor ${
					isPrevDisabled && "cursor-not-allowed"
				}`}
			>
				{t("orders.previous")}
			</button>
			{currentPage > START_END_PAGES && totalPages > START_END_PAGES && (
				<>
					<button
						onClick={() => onPageChange(1)}
						className="px-4 py-2 rounded-lg bg-hoverColor text-passiveTextColor"
					>
						1
					</button>
					<button
						className={`px-3 py-2 rounded-lg bg-hoverColor text-passiveTextColor cursor-default`}
					>
						...
					</button>
				</>
			)}
			{Array.from(
				{ length: getEndRange() - getStartRange() + 1 },
				(_, index) => index + getStartRange()
			).map((page) => (
				<button
					key={page}
					onClick={() => onPageChange(page)}
					className={`px-4 py-2 rounded-lg ${
						page === currentPage
							? "bg-mainColor text-whiteColor"
							: "bg-hoverColor text-passiveTextColor"
					}`}
				>
					{page}
				</button>
			))}
			{currentPage < totalPages - START_END_PAGES + 1 &&
				totalPages > START_END_PAGES && (
					<>
						<button
							className={`px-3 py-2 rounded-lg bg-hoverColor text-passiveTextColor cursor-default`}
						>
							...
						</button>

						<button
							onClick={() => onPageChange(totalPages)}
							className="px-4 py-2 rounded-lg bg-hoverColor text-passiveTextColor"
						>
							{totalPages}
						</button>
					</>
				)}
			<button
				onClick={() => onPageChange(currentPage + 1)}
				disabled={isNextDisabled}
				className={`px-3 py-2 rounded-lg bg-hoverColor text-passiveTextColor ${
					isNextDisabled && "cursor-not-allowed"
				}`}
			>
				{t("orders.next")}
			</button>
		</div>
	);
};

export default Pagination;
