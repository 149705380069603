import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { languages, defaultLanguage } from "./i18.constants";

const language_codes = languages.map((item) => {
	return item.iso;
});

const language = localStorage.getItem("i18nextLng") || defaultLanguage;

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: language,
		fallbackLng: defaultLanguage,
		debug: true,
		supportedLngs: language_codes,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
