import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/chevron-down.svg";
import { SelectProps } from "./Select.props";
import { SelectItemProps } from "./SelectItem";

export const Select: React.FC<SelectProps> = ({
	label,
	children,
	defaultSelectLabel = "Select property",
	className,
	disabled = false,
	onSelect,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedLabel, setSelectedLabel] = useState(defaultSelectLabel);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const getStatusClass = (status: string) => {
		const loweredStatus = status.toLowerCase();
		switch (loweredStatus) {
			case "preparing":
				return "text-[#BF310A]";
			case "delivered":
				return "text-[#2DAA6F]";
			case "return":
			case "returned":
				return "text-[#101828]";
			case "cancel":
			case "cancelled":
				return "text-[#F21414]";
			case "waiting":
				return "text-[#FE9A04]";
			default:
				return "";
		}
	};

	const isDefaultSelect = (itemValue: string): boolean => {
		if (itemValue === "Cancel" && defaultSelectLabel === "Cancelled")
			return true;
		if (itemValue === "Return" && defaultSelectLabel === "Returned")
			return true;
		if (itemValue === defaultSelectLabel) return true;
		return false;
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (e: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(e.target as Node)
		) {
			setIsOpen(false);
		}
	};

	const handleItemSelect = (value: string, label: string) => {
		setSelectedLabel(label);
		if (onSelect) {
			onSelect(value);
		}
		setIsOpen(false);
	};

	return (
		<div
			className={`relative grid gap-2 ${disabled ? "opacity-60" : ""}`}
			ref={dropdownRef}
		>
			{" "}
			{/* Use the disabled prop to reduce opacity when disabled */}
			{label && (
				<label className="block text-sm font-medium text-textColor">
					{label}
				</label>
			)}
			<div
				onClick={() => !disabled && setIsOpen(!isOpen)}
				className={`grid items-center w-full text-sm font-medium  px-4 py-3 transition-all duration-300 ease-in-out border rounded-lg cursor-pointer ${
					disabled ? "cursor-not-allowed" : ""
				} grid-cols-frMax border-strokeColor bg-whiteColor duration-400 hover:border-black focus:border-black focus:outline-none ${getStatusClass(
					selectedLabel
				)}`}
			>
				{selectedLabel}
				<ArrowDownIcon className="stroke-passiveTextColor" />
			</div>
			{isOpen && !disabled && (
				<div className="absolute z-10 w-full mt-1 transition-all duration-300 border rounded-lg shadow-lg top-full border-strokeColor bg-whiteColor">
					{React.Children.map(children, (child) => {
						if (
							React.isValidElement<SelectItemProps>(child) &&
							child.props.value !== selectedLabel &&
							!isDefaultSelect(child.props.value)
						) {
							return React.cloneElement(child, {
								onSelect: (value: string) => {
									const childLabel =
										typeof child.props.children === "string"
											? child.props.children
											: "Default Label"; // or some other handling here
									handleItemSelect(value, childLabel);
								},
							});
						}
						return null;
					})}
				</div>
			)}
		</div>
	);
};
