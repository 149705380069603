import OrderStatus from "common/OrderStatus/OrderStatus";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

interface OrdersTableProps {
	orders: any;
	onRowClick: (order: any) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, onRowClick }) => {
	const { t } = useTranslation();
	return (
		<table className="w-full border rounded-lg border-strokeColor">
			<thead className="bg-white border border-strokeColor">
				<tr>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.id")}
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.products")}
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.customersPhone")}
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.address")}
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.status")}
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						{t("orders.price")}
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{orders.map((order: any, idx: any) => (
					<tr
						key={idx}
						className={`${
							idx === orders.length - 1
								? "border-0"
								: "border-b border-strokeColor"
						} ${idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"} cursor-pointer`}
						onClick={() => onRowClick(order)}
					>
						<td
							className={`px-5 py-4 text-textColor font-medium ${
								idx === orders.length - 1 ? "rounded-bl-lg" : ""
							}`}
						>
							{order._id.slice(0, 10)}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{order.products
								.map(
									(product: any) => `${getTranslation(product.product.name)}`
								)
								.join(", ")}
						</td>
						<td className="px-5 py-4">
							<div className="font-medium text-textColor">
								{order.shippingAddress.fullName}
							</div>
							<div className="text-passiveTextColor font-regular">
								{order.shippingAddress.phone}
							</div>
						</td>
						<td className="px-5 py-4 text-textColor font-regular">
							{order.shippingAddress.address1}
						</td>
						<td className="px-5 py-4">
							<OrderStatus status={order.orderStatus} />
						</td>
						<td
							className={`px-5 py-4 text-textColor font-regular ${
								idx === orders.length - 1 ? "rounded-br-lg" : ""
							}`}
						>
							{order.orderSummary.total} {serviceConfig.currencySign}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default OrdersTable;
