import { P } from "common/P/P";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { useTranslation } from "react-i18next";
import getTranslation from "utils/getTranslation";

const ColorSelection = ({
	selectedColor,
	setSelectedColor,
	colorData,
	onColorSelected, // Prop to handle when a color is selected
}: any) => {
	const { t } = useTranslation();
	const handleColorClick = (colorId: any) => {
		setSelectedColor(colorId); // Set the selected color
		if (onColorSelected) {
			onColorSelected(colorId); // Call the handler only if it's provided
		}
	};

	return (
		<div className="grid gap-2 mt-6">
			<P size="p3" type="medium">
				{t("products.selectColor")}
			</P>
			<div className="flex flex-wrap gap-2">
				{colorData.map((color: any) => (
					<StyleManagerItem
						key={color._id}
						label={getTranslation(color?.name) || "Unnamed Color"}
						paddingY="py-2"
						onClick={() => handleColorClick(color._id)}
						className={`${
							selectedColor === color._id
								? "bg-black text-white"
								: "bg-white text-black"
						} cursor-pointer`}
					/>
				))}
			</div>
		</div>
	);
};

export default ColorSelection;
