import { P } from "common/P/P";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { useTranslation } from "react-i18next";

const ClothingSizeSelection = ({
	selectedSize,
	setSelectedSize,
	sizeData,
	onSizeSelected,
}: any) => {
	const { t } = useTranslation();
	const handleSizeClick = (id: any) => {
		setSelectedSize(id); // Set the selected color
		onSizeSelected && onSizeSelected(); // Call the handler to signal that a color has been selected
	};
	return (
		<div className="grid gap-2">
			<P size="p3" type="medium">
				{t("products.selectSize")}
			</P>
			<div className="flex flex-wrap gap-2">
				{sizeData.map((size: any) => (
					<StyleManagerItem
						key={size._id}
						label={size.size}
						paddingY="py-2"
						onClick={() => handleSizeClick(size._id)}
						className={`${
							selectedSize === size._id
								? "bg-black text-white"
								: "bg-white text-black"
						} cursor-pointer`}
					/>
				))}
			</div>
		</div>
	);
};

export default ClothingSizeSelection;
