import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import ContactCard from "components/ContactCard/ContactCard";
import { serviceConfig } from "configs";
import Spinner from "modules/loaders/Spinner/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ContactPage: React.FC = () => {
	const [companies, setCompanies] = useState<any[]>([]);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingStates, setLoadingStates] = useState({
		Men: false,
		Women: false,
		Home: false,
	});

	useEffect(() => {
		// Fetch all companies on mount
		fetchCompanies();
	}, []);

	const fetchCompanies = async () => {
		setIsLoading(true);
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/company`);
			const data = await response.json();
			setCompanies(data.data);
			setIsLoading(false);
		} catch (error) {
			console.error("Failed to fetch companies:", error);
			setIsLoading(false);
		}
	};

	const findCompanyBySection = (section: string) => {
		return companies.find((company) => company.section === section);
	};

	const handleSave = async (data: any, section: string) => {
		try {
			setLoadingStates((prev) => ({ ...prev, [section]: true }));
			const existingCompany = findCompanyBySection(section);

			const requestOptions: RequestInit = {
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			};

			if (existingCompany) {
				// Update
				requestOptions.method = "PUT";
				const response = await fetch(
					`${serviceConfig.apiUrl}/company/${existingCompany._id}`,
					requestOptions
				);
				const result = await response.json();
				setLoadingStates((prev) => ({ ...prev, [section]: false }));
				console.log(result);
			} else {
				// Create
				requestOptions.method = "POST";
				const response = await fetch(
					`${serviceConfig.apiUrl}/company`,
					requestOptions
				);

				const result = await response.json();
				setLoadingStates((prev) => ({ ...prev, [section]: false }));
				console.log(result);
			}

			toast.success(t("contact.successResponse"));
			// Refresh companies to reflect the changes
			fetchCompanies();
		} catch (error) {
			toast.error(t("contact.errorResponse"));
		}
	};

	const handleSaveForMen = (data: any) => handleSave(data, "Men");
	const handleSaveForWomen = (data: any) => handleSave(data, "Women");
	const handleSaveForHome = (data: any) => handleSave(data, "Home");

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					{t("contact.title")}
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					{t("others.subTitlePages")}
				</P>
			</div>
			<div className="grid grid-cols-1 gap-6 mt-8 TwoXl:grid-cols-maxTwo">
				<ContactCard
					label="Tellale Men"
					onSave={handleSaveForMen}
					companyData={findCompanyBySection("Men")}
					isLoading={loadingStates.Men}
				/>
				<ContactCard
					label="Tellale Women"
					onSave={handleSaveForWomen}
					companyData={findCompanyBySection("Women")}
					isLoading={loadingStates.Women}
				/>
				<ContactCard
					label="Tellale Home"
					onSave={handleSaveForHome}
					companyData={findCompanyBySection("Home")}
					isLoading={loadingStates.Women}
				/>
			</div>
		</div>
	);
};

export default ContactPage;
