interface Translatable {
	en: string;
	ru: string;
	tk: string;
}

interface TranslatableSlider {
	name_en: string;
	name_ru: string;
	name_tk: string;
}

type Language = "en" | "ru" | "tk"; // Simplified for clarity

const getTranslation = (
	translatable: Translatable | TranslatableSlider | undefined,
	isSlider: boolean = false
): string => {
	const currentLang: Language =
		(localStorage.getItem("i18nextLng") as Language) || "en";

	if (translatable) {
		if (isSlider) {
			const sliderLangKey = `name_${currentLang}` as keyof TranslatableSlider;
			return (
				(translatable as TranslatableSlider)[sliderLangKey] ||
				(translatable as TranslatableSlider).name_en
			);
		} else {
			return (
				(translatable as Translatable)[currentLang] ||
				(translatable as Translatable).en
			);
		}
	}

	return "";
};

export default getTranslation;
