import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import CategoriesCard from "components/CategoriesCard/CategoriesCard";
import { Button } from "common/Button/Button";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import SubCategoryProductList from "components/SubCategoryProductList/SubCategoryProductList";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";
import { toast } from "react-toastify";

const CategoriesPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState<any>(t("categories.all"));
	const [selectedSubcategory, setSelectedSubcategory] = useState(null);
	const [isManagePanelOpen, setManagePanelOpen] = useState(false);
	const [rusValue, setRusValue] = useState("");
	const [engValue, setEngValue] = useState("");
	const [turkmenValue, setTurkmenValue] = useState("");
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(
		null
	);

	const [categories, setCategories] = useState<any>([]); // This state will hold our fetched categories.

	const [isLoading, setIsLoading] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		fetch(`${serviceConfig.apiUrl}/categories?isAdmin=true`) // Replace this with your API endpoint if different
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setCategories(data.data);
					setIsLoading(false);
				} else {
					console.error("Failed to fetch categories");
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.error("Error fetching categories:", error);
				setIsLoading(false);
			});
	}, []);
	const filteredData =
		filter === "all"
			? categories
			: categories.filter((category: any) =>
					filter === "active" ? category.active : !category.active
			  );

	const onSaveSubSubCategory = async () => {
		setIsModalLoading(true);

		if (!selectedCategory || !selectedSubCategory) {
			toast.error(t("categories.selectCategorySubcategoryError"));
			setIsModalLoading(false);
			return;
		}

		if (!engValue || !rusValue || !turkmenValue) {
			toast.error(t("categories.fillAllFieldsError"));
			setIsModalLoading(false);
			return;
		}

		const url = `${serviceConfig.apiUrl}/categories/${selectedCategory}/subcategories/${selectedSubCategory}/subsubcategories`;
		const requestBody = {
			name: {
				en: engValue,
				ru: rusValue,
				tk: turkmenValue,
			},
			isActive: true,
		};

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});

			const responseData = await response.json();

			if (responseData.success) {
				toast.success(t("categories.addSubSubcategorySuccess"));
				setManagePanelOpen(false);
				window.location.reload();
			} else {
				toast.error(t("categories.addSubSubcategoryError"));
			}
		} catch (error) {
			toast.error(t("categories.addSubSubcategoryError"));
			console.error("Failed to send request:", error);
		} finally {
			setIsModalLoading(false);
		}
	};

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					{t("categories.title")}
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					{t("others.subTitlePages")}
				</P>
			</div>
			<div className="grid gap-6 mt-8 grid-cols-maxFr">
				<CategoriesCard
					data={filteredData}
					setSelectedSubcategory={setSelectedSubcategory}
				/>
				<div className="grid gap-4 h-fit">
					<Button
						appearance="black"
						icon={<PlusIcon />}
						width="full"
						className="px-5 place-content-start"
						onClick={() => setManagePanelOpen(true)}
					>
						{t("categories.addSubSubcategory")}
					</Button>

					{selectedSubcategory && (
						<SubCategoryProductList
							category={selectedSubcategory}
							data={categories}
						/>
					)}
				</div>
			</div>
			<ManagePanel
				isOpen={isManagePanelOpen}
				onClose={() => setManagePanelOpen(false)}
				label={t("categories.addSubSubcategory")}
				onSave={onSaveSubSubCategory}
				onDiscard={() => {
					console.log("Discard clicked");
					setManagePanelOpen(false);
				}}
				isLoading={isModalLoading}
			>
				<div className="grid gap-6">
					<Select
						onSelect={(value) => {
							setSelectedCategory(value);
							setSelectedSubCategory(null);
						}}
						defaultSelectLabel={
							selectedCategory || t("categories.selectCategory")
						}
						label={t("categories.category")}
					>
						{categories.map((item: any) => (
							<SelectItem value={item._id} key={item._id}>
								{item.name.en}
							</SelectItem>
						))}
					</Select>
					<Select
						key={selectedCategory || "default-key"}
						onSelect={(value) => setSelectedSubCategory(value)}
						defaultSelectLabel={
							selectedSubCategory || t("categories.selectSubCategory")
						}
						label={t("categories.subcategory")}
						disabled={
							!selectedCategory ||
							!categories.find((item: any) => item._id === selectedCategory)
								?.subCategories.length
						}
					>
						{selectedCategory
							? categories
									.find((item: any) => item._id === selectedCategory)
									?.subCategories.filter((sub: any) => sub.isActive)
									.map((sub: any) => (
										<SelectItem value={sub._id} key={sub._id}>
											{sub.name.en}
										</SelectItem>
									))
							: null}
					</Select>

					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="rusValue"
						label="Русский"
						placeholder="Например: Платья"
						value={rusValue}
						onChange={(e) => setRusValue(e.target.value)}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="engValue"
						label="English"
						placeholder="For example: Dresses"
						value={engValue}
						onChange={(e) => setEngValue(e.target.value)}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="turkmenValue"
						label="Türkmen"
						placeholder="Meselem: Köýnekler"
						value={turkmenValue}
						onChange={(e) => setTurkmenValue(e.target.value)}
					/>
				</div>
			</ManagePanel>
		</div>
	);
};

export default CategoriesPage;
