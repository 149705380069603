// EditProductPanel.jsx
import React, { useState, useCallback, useEffect } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { P } from "common/P/P";
import { Button } from "common/Button/Button";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "common/assets/icons/minus.svg";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Checkbox from "common/Checkbox/Checkbox";
import ColorSelection from "components/ColorSelection/ColorSelection";
import ProductImageUpload from "common/Image/ProductImageUpload";
import ClothingSizeSelection from "components/ClothingSizeSelection/ClothingSizeSelection";
import ShoesSizeSelection from "components/ShoesSizeSelection/ShoesSizeSelection";
import { serviceConfig } from "configs";

interface IPropsEditProductPanelProps {
	isOpen: boolean;
	onClose: any;
	product: any;
	onSaveSuccess: any;
}

const EditProductPanel: React.FC<IPropsEditProductPanelProps> = ({
	isOpen,
	onClose,
	product,
	onSaveSuccess,
}) => {
	const { t } = useTranslation();
	console.log("------ PRODUCT ------ ", product);
	const [productInfo, setProductInfo] = useState<any>({
		name_ru: "",
		name_en: "",
		name_tk: "",
		description: {
			ru: "",
			en: "",
			tk: "",
		},
		delivery_info: {
			ru: "",
			en: "",
			tk: "",
		},
		return_policy: {
			ru: "",
			en: "",
			tk: "",
		},
		features: {
			ru: "",
			en: "",
			tk: "",
		},
		isChecked: false,
		sizes: [
			{
				priceValue: "",
				discountValue: "",
				quantityValue: "",
			},
		],
	});

	const [productVariations, setProductVariations] = useState<any[]>([
		{
			id: 0,
			color: null,
			images: [],
			imageFiles: [],
			sizes: [
				{
					id: 0,
					sizeId: "",
					size: null,
					type: "clothing", // default type set here
					priceValue: "",
					discountValue: "",
					quantityValue: "",
					discountPrice: "",
					discounted: false,
				},
			],
		},
	]);

	console.log("------- VARIANNTTTT", productVariations);

	const [colors, setColors] = useState<any>([]);
	const [clothingSizes, setClothingSizes] = useState<any>([]);
	const [shoeSizes, setShoeSizes] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [selectedVariationIndex, setSelectedVariationIndex] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				let response = await fetch(`${serviceConfig.apiUrl}/color`);
				let data = await response.json();
				setColors(data.data);

				response = await fetch(`${serviceConfig.apiUrl}/clothing-size`);
				data = await response.json();
				setClothingSizes(data.data);

				response = await fetch(`${serviceConfig.apiUrl}/shoes-size`);
				data = await response.json();
				setShoeSizes(data.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (product) {
			setProductInfo({
				name_ru: product.name.ru,
				name_en: product.name.en,
				name_tk: product.name.tk,
				description: product.description,
				delivery_info: product.delivery_info,
				return_policy: product.return_policy,
				features: product.features,
				isChecked: product.bestSelling,
			});

			const variations = product.colors.map(
				(color: any, colorIndex: number) => {
					const sizes = [
						...color.clothingSizes.map((size: any) => ({
							...size,
							type: "clothing",
						})),
						...color.shoesSizes.map((size: any) => ({
							...size,
							type: "shoe",
						})),
					];

					return {
						id: colorIndex,
						color: color.name, // Assuming name is an object { tk, ru, en }
						// colorId: color._id,
						colorId: color.colorId ? color.colorId : color._id,
						images: color.images.map((image: any) => ({
							id: Date.now() + Math.random(),
							url: `${serviceConfig.apiUrl}${image}`,
						})),
						imageFiles: [],
						sizes: sizes.map((size, sizeIndex) => ({
							id: sizeIndex,
							sizeId: size.sizeId,
							type: size.type, // 'clothing' or 'shoe'
							priceValue: size.price,
							discountValue: size.discount_percentage,
							discountPrice: size.discount_price,
							discounted: size.discounted,
							quantityValue: size.stock,
						})),
					};
				}
			);

			setProductVariations(variations);
		}
	}, [product]);

	const handleInputChange = useCallback((name: any, value: any) => {
		setProductInfo((prev: any) => {
			const keys = name.split(".");
			if (keys.length > 1) {
				const [firstKey, secondKey] = keys;
				return {
					...prev,
					[firstKey]: {
						...prev[firstKey],
						[secondKey]: value,
					},
				};
			} else {
				return { ...prev, [name]: value };
			}
		});
	}, []);

	const handleSelectionChange = useCallback((name: any, value: any) => {
		setProductInfo((prev: any) => {
			// Specifically handle the isChecked property
			if (name === "isChecked") {
				return { ...prev, isChecked: value };
			}

			// Handle other properties if any
			// Your existing logic for other selections can go here

			return prev; // Return previous state by default
		});
	}, []);

	const formatDiscountPlaceholder = (price: any, discount: any) => {
		// Extract the numeric part from the discount string
		const discountPercentage = parseFloat(discount);

		// Check if price or discount is not provided, or if discountPercentage is NaN
		if (!price || isNaN(discountPercentage)) {
			return "N/A";
		}

		const discountAmount = (parseFloat(price) * discountPercentage) / 100;
		return (parseFloat(price) - discountAmount).toFixed(2);
	};

	const handleImageUpload = (imageFile: any, variationIndex: number) => {
		const imageUrl = URL.createObjectURL(imageFile);
		const imageId = Date.now();

		setProductVariations((prevVariations) =>
			prevVariations.map((variation, index) => {
				if (index === variationIndex) {
					return {
						...variation,
						images: [...variation.images, { id: imageId, url: imageUrl }], // Add blob URL
						imageFiles: [
							...variation.imageFiles,
							{ id: imageId, file: imageFile },
						],
					};
				}
				return variation;
			})
		);
	};

	const handleImageDelete = async (imageId: any, variationIndex: any) => {
		const variation = productVariations[variationIndex];
		const imageToDelete = variation.images.find(
			(image: any) => image.id === imageId
		);

		if (imageToDelete.url.startsWith("blob:")) {
			removeImageFromState(imageId, variationIndex);
		} else {
			if (window.confirm("Are you sure you want to delete this image?")) {
				try {
					// Call the API to delete the image
					await apiDeleteImage(product, imageToDelete.url);
					removeImageFromState(imageId, variationIndex);
					toast.success("Image deleted successfully.");
				} catch (error) {
					toast.error("Failed to delete image.");
				}
			}
		}
	};

	const apiDeleteImage = async (product: any, imageLink: any) => {
		// Construct the URL for the request
		const url = `${serviceConfig.apiUrl}/products/${product._id}/delete-image`;
		const relativeImagePath = imageLink.substring(
			imageLink.indexOf("/get-image/")
		);

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ image: relativeImagePath }), // Send imageLink in the body
			});
			const data = await response.json();
			return data;
		} catch (error) {
			console.error("Error deleting image:", error);
			throw error;
		}
	};

	const removeImageFromState = (imageId: any, variationIndex: number) => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, idx) => {
				if (idx === variationIndex) {
					const updatedImages = variation.images.filter(
						(image: any) => image.id !== imageId
					);
					const updatedImageFiles = variation.imageFiles.filter(
						(file: any) => file.id !== imageId
					);

					// Revoke the URL for the blob image
					if (
						updatedImages
							.find((image: any) => image.id === imageId)
							?.url.startsWith("blob:")
					) {
						URL.revokeObjectURL(
							updatedImages.find((image: any) => image.id === imageId).url
						);
					}

					return {
						...variation,
						images: updatedImages,
						imageFiles: updatedImageFiles,
					};
				}
				return variation;
			})
		);
	};

	const addNewProductSize = (variationIndex: any, defaultType = "clothing") => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, index) => {
				if (index === variationIndex) {
					const newSize = {
						id: Date.now(), // Using a timestamp to ensure a unique ID
						sizeId: "", // Adjust based on your data structure
						type: defaultType,
						priceValue: "",
						discountValue: "",
						quantityValue: "",
						discountPrice: "",
						discounted: false,
					};
					return {
						...variation,
						sizes: [...variation.sizes, newSize],
					};
				}
				return variation;
			})
		);
	};

	const addNewProductColor = () => {
		const newColor = {
			id: Date.now(), // Ensuring a unique ID
			color: { tk: "", ru: "", en: "" }, // Assuming you need an object here
			colorId: "", // Initialize as needed
			images: [],
			imageFiles: [],
			sizes: [
				{
					id: Date.now(), // Ensuring a unique ID for the size too
					size: null,
					type: "clothing", // Default type
					priceValue: "",
					discountValue: "",
					quantityValue: "",
					discountPrice: "",
					discounted: false,
				},
			],
		};

		setProductVariations((prevVariations) => [...prevVariations, newColor]);
	};

	const handleSave = useCallback(async () => {
		if (!product?._id) {
			toast.error("Product ID is missing.");
			return;
		}

		if (!productInfo.name_ru || !productInfo.name_en || !productInfo.name_tk) {
			toast.error("Please fill in all the required fields.");
			return;
		}

		const formData = new FormData();

		const productData = {
			name: {
				ru: productInfo.name_ru,
				en: productInfo.name_en,
				tk: productInfo.name_tk,
			},
			description: productInfo.description,
			features: productInfo.features,
			delivery_info: productInfo.delivery_info,
			return_policy: productInfo.return_policy,
			bestSelling: productInfo.isChecked,
			colors: productVariations.map((variation) => ({
				colorId: variation.colorId,
				clothingSizes: variation.sizes
					.filter((size: any) => size.type === "clothing")
					.map((size: any) => ({
						sizeId: size.sizeId,
						price: size.priceValue,
						discount_price:
							size.discountValue > 0 ? size.discountPrice : size.priceValue,
						discount_percentage: size.discountValue,
						discounted:
							size.discountValue !== undefined &&
							size.discountValue !== null &&
							size.discountValue.toString().length > 0,
						stock: size.quantityValue,
					})),
				shoesSizes: variation.sizes
					.filter((size: any) => size.type === "shoe")
					.map((size: any) => ({
						sizeId: size.sizeId,
						price: size.priceValue,
						discount_price:
							size.discountValue > 0 ? size.discountPrice : size.priceValue,
						discount_percentage: size.discountValue,
						discounted:
							size.discountValue !== undefined &&
							size.discountValue !== null &&
							size.discountValue.toString().length > 0,
						stock: size.quantityValue,
					})),
				images: variation.images
					.filter((image: any) => !image.url.startsWith("blob:"))
					.map((image: any) => {
						const basePathIndex = image.url.indexOf("/get-image/");
						if (basePathIndex !== -1) {
							return image.url.substring(basePathIndex);
						}
						return image.url;
					}),
			})),
		};

		formData.append("productData", JSON.stringify(productData));

		// Append images as files with correct indexing
		productVariations.forEach((variation, variationIndex) => {
			variation.imageFiles.forEach((file: any, fileIndex: any) => {
				if (file && file.file instanceof Blob) {
					const fieldName = `colors[${variationIndex}].images[${fileIndex}]`;
					formData.append(fieldName, file.file);
				}
			});
		});

		try {
			setIsModalLoading(true);
			const response = await fetch(
				`${serviceConfig.apiUrl}/products/${product._id}`,
				{
					method: "PUT",
					body: formData,
				}
			);

			const data = await response.json();

			if (data.success) {
				toast.success("Product updated successfully.");
			} else {
				toast.error("Failed to update product.");
			}
		} catch (error) {
			console.error("Error updating product:", error);
			toast.error("Error updating product.");
		} finally {
			setIsModalLoading(false);
			window.location.reload();
		}
	}, [product, productInfo, productVariations]);

	const handlePriceChange = (
		variationIndex: number,
		sizeIndex: number,
		newValue: any
	) => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, vIndex) => {
				if (vIndex === variationIndex) {
					return {
						...variation,
						sizes: variation.sizes.map((size: any, sIndex: number) => {
							if (sIndex === sizeIndex) {
								return { ...size, priceValue: newValue };
							}
							return size;
						}),
					};
				}
				return variation;
			})
		);
	};

	const handleDiscountChange = (
		variationIndex: number,
		sizeIndex: number,
		newValue: any
	) => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, vIndex) => {
				if (vIndex === variationIndex) {
					return {
						...variation,
						sizes: variation.sizes.map((size: any, sIndex: number) => {
							if (sIndex === sizeIndex) {
								// Calculate the discounted price based on the new discount value
								const discountedPrice =
									size.priceValue - size.priceValue * (newValue / 100);
								return {
									...size,
									discountValue: newValue,
									discountPrice: discountedPrice.toString(),
								};
							}
							return size;
						}),
					};
				}
				return variation;
			})
		);
	};

	const handleQuantityChange = (
		variationIndex: number,
		sizeIndex: number,
		newValue: any
	) => {
		setProductVariations((prevVariations) =>
			prevVariations.map((variation, vIndex) => {
				if (vIndex === variationIndex) {
					return {
						...variation,
						sizes: variation.sizes.map((size: any, sIndex: number) => {
							if (sIndex === sizeIndex) {
								return { ...size, quantityValue: newValue };
							}
							return size;
						}),
					};
				}
				return variation;
			})
		);
	};

	const updateSize = (
		variationIndex: number,
		sizeIndex: number,
		newSizeId: any,
		type: any
	) => {
		setProductVariations((prevVariations) => {
			const newVariations = [...prevVariations];

			const currentSizeObject =
				newVariations[variationIndex].sizes[sizeIndex].sizeId;

			const updatedSizeId = {
				...currentSizeObject,
				_id: newSizeId._id ? newSizeId._id : newSizeId,
			};

			const updatedSize = {
				...newVariations[variationIndex].sizes[sizeIndex],
				sizeId: updatedSizeId,
				type,
			};

			newVariations[variationIndex].sizes[sizeIndex] = updatedSize;

			return newVariations;
		});
	};

	return (
		<ManagePanel
			isOpen={isOpen}
			onClose={onClose}
			label={`Edit product`}
			onSave={handleSave}
			onDiscard={onClose}
			isLoading={isModalLoading}
		>
			<div className="grid gap-6">
				<div className="grid gap-6">
					<div className="grid gap-4">
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="name_ru"
							label={t("products.name")}
							placeholder="Русский: Кимоно"
							value={productInfo.name_ru}
							onChange={(e: any) =>
								handleInputChange(e.target.name, e.target.value)
							}
						/>
						<Input
							appearance="default"
							type="text"
							name="name_en"
							placeholder="English: Kimono"
							value={productInfo.name_en}
							onChange={(e: any) =>
								handleInputChange(e.target.name, e.target.value)
							}
						/>
						<Input
							appearance="default"
							type="text"
							name="name_tk"
							placeholder="Türkmen: Kimono"
							value={productInfo.name_tk}
							onChange={(e: any) =>
								handleInputChange(e.target.name, e.target.value)
							}
						/>
					</div>
				</div>
				<Checkbox
					id="Bestselling"
					label={t("products.bestselling")}
					checked={productInfo.isChecked}
					onChange={(checked: any) =>
						handleSelectionChange("isChecked", checked)
					}
				/>

				<div className="grid gap-4">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="description.ru"
						label="Descriptions"
						placeholder="Описание продукта"
						value={productInfo.description.ru}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="description.en"
						placeholder="English: Product description"
						value={productInfo.description.en}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="description.tk"
						placeholder="Türkmen: Harydyň düşündirilişi"
						value={productInfo.description.tk}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
				</div>

				<div className="grid gap-4">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="delivery_info.ru"
						label="О доставке"
						placeholder="Информация о доставке"
						value={productInfo.delivery_info.ru}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="delivery_info.en"
						placeholder="English: Information about delivery"
						value={productInfo.delivery_info.en}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="delivery_info.tk"
						placeholder="Türkmen: Eltip bermek informasiyasy"
						value={productInfo.delivery_info.tk}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
				</div>

				<div className="grid gap-4">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="return_policy.ru"
						label="О Возврате"
						placeholder="Информация о возврате"
						value={productInfo.return_policy.ru}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="return_policy.en"
						placeholder="English: Information about return"
						value={productInfo.return_policy.en}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>

					<Input
						appearance="default"
						type="text"
						name="return_policy.tk"
						placeholder="Türkmen: Yza gaytarmak informasiyasy"
						value={productInfo.return_policy.tk}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
				</div>

				<div className="grid gap-4">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="features.ru"
						label={t("products.features")}
						placeholder="Русский: Особенности продукта"
						value={productInfo.features.ru}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
					<Input
						appearance="default"
						type="text"
						name="features.en"
						placeholder="English: Product features"
						value={productInfo.features.en}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
					<Input
						appearance="default"
						type="text"
						name="features.tk"
						placeholder="Türkmen: Harydyň aýratynlygy"
						value={productInfo.features.tk}
						onChange={(e: any) =>
							handleInputChange(e.target.name, e.target.value)
						}
					/>
				</div>
			</div>
			{productVariations.map((variation, variationIndex) => (
				<div key={variationIndex} className="grid grid-cols-1 gap-4">
					{/* Color selection input */}
					<ColorSelection
						selectedColor={variation.colorId}
						setSelectedColor={(colorId: any) => {
							let updatedVariations = productVariations.map(
								(varItem, varIndex) => {
									if (varIndex === variationIndex) {
										return { ...varItem, colorId: colorId };
									}
									return varItem;
								}
							);
							setProductVariations(updatedVariations);
						}}
						colorData={colors}
					/>

					{/* Image upload input */}
					<div className="flex items-center place-content-end">
						<label
							htmlFor={`variation-${variationIndex}`}
							className="mr-2 text-sm text-gray-700 cursor-pointer"
						>
							{t("products.selectVariant")} {variationIndex + 1}
						</label>
						<input
							type="radio"
							id={`variation-${variationIndex}`}
							name="variation-selection"
							value={variationIndex}
							checked={selectedVariationIndex === variationIndex}
							onChange={() => setSelectedVariationIndex(variationIndex)}
							className={`form-radio cursor-pointer h-4 w-4 text-black ${
								selectedVariationIndex === variationIndex
									? "accent-2 accent-black"
									: ""
							}`}
						/>
					</div>

					<ProductImageUpload
						images={variation.images}
						onImageUpload={(imageFile: any) => {
							handleImageUpload(imageFile, selectedVariationIndex);
						}}
						onImageDelete={(imageId: any) =>
							handleImageDelete(imageId, selectedVariationIndex)
						}
						variationIndex={selectedVariationIndex}
						label={`Picture for Color ${variationIndex + 1}`}
					/>

					{/* Sizes for the current variation */}
					{variation.sizes.map((size: any, sizeIndex: any) => (
						<div key={sizeIndex} className="grid grid-cols-1 gap-4">
							<ClothingSizeSelection
								selectedSize={
									typeof size.sizeId === "object" &&
									size.sizeId !== null &&
									size.sizeId._id
										? size.sizeId._id
										: size.sizeId
								}
								setSelectedSize={(sizeId: any) =>
									updateSize(variationIndex, sizeIndex, sizeId, "clothing")
								}
								sizeData={clothingSizes}
								onSizeSelected={() => {}}
							/>

							<ShoesSizeSelection
								selectedSize={
									typeof size.sizeId === "object" &&
									size.sizeId !== null &&
									size.sizeId._id
										? size.sizeId._id
										: size.sizeId
								}
								setSelectedSize={(sizeId: any) =>
									updateSize(variationIndex, sizeIndex, sizeId, "shoe")
								}
								sizeData={shoeSizes}
								onSizeSelected={() => {}}
							/>

							<div className="grid gap-6 grid-cols-maxTwo">
								<Input
									price={true}
									className="w-40 pl-20"
									appearance="default"
									type="text"
									name={`priceValue_${variationIndex}_${sizeIndex}`}
									label={t("products.price")}
									value={size.priceValue}
									onChange={(e) =>
										handlePriceChange(variationIndex, sizeIndex, e.target.value)
									}
									placeholder="0"
								/>
								<Input
									discount={true}
									appearance="default"
									type="text"
									name={`discountValue_${variationIndex}_${sizeIndex}`}
									label={t("products.discount")}
									discountedPrice={
										`${formatDiscountPlaceholder(
											size.priceValue,
											size.discountValue
										)} ${serviceConfig.currencySign}` || `N/A ${serviceConfig.currencySign}`
									}
									placeholder="20%"
									value={size.discountValue}
									onChange={(e) =>
										handleDiscountChange(
											variationIndex,
											sizeIndex,
											e.target.value
										)
									}
								/>
							</div>
							<Input
								withlabel={true}
								appearance="default"
								type="text"
								name={`quantityValue_${variationIndex}_${sizeIndex}`}
								label={t("products.quantity")}
								placeholder={t("placeholders.stock")}
								value={size.quantityValue}
								onChange={(e) =>
									handleQuantityChange(
										variationIndex,
										sizeIndex,
										e.target.value
									)
								}
							/>
						</div>
					))}

					{/* Button to add a new size for the current variation */}

					<button
						onClick={() => addNewProductSize(variationIndex)}
						className="grid gap-1 cursor-pointer grid-cols-maxTwo place-items-center place-content-end"
					>
						<PlusIcon className="stroke-black" />
						<P size="p2" type="regular" className="cursor-pointer">
							{t("products.addSize")}
						</P>
					</button>

					{/* Button to add a new color variation */}
					{(variationIndex === productVariations.length - 1 ||
						productVariations.length === 1) && (
						<Button
							onClick={addNewProductColor}
							appearance="black"
							width="full"
							icon={<PlusIcon />}
						>
							{t("products.addNewColor")}
						</Button>
					)}
				</div>
			))}
		</ManagePanel>
	);
};

export default EditProductPanel;
