import { Navigate } from "react-router-dom";
import { routeConstants } from "navigation";
import { getCookie } from "utils/cookie";

interface PrivateRouteProps {
	element: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
	return getCookie("token") ? (
		element
	) : (
		<Navigate to={routeConstants.login.route} replace />
	);
};
