import React, { useEffect, useState } from "react";

import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { ReactComponent as LogoutIcon } from "common/assets/icons/log-out.svg";
import { Link, useLocation } from "react-router-dom";
import { routeConstants } from "navigation";

import { ReactComponent as SliderIcon } from "common/assets/icons/media-image.svg";
import { ReactComponent as CategoryIcon } from "common/assets/icons/bar-chart-2.svg";
import { ReactComponent as ProductsIcon } from "common/assets/icons/t-shirt.svg";
import { ReactComponent as OrdersIcon } from "common/assets/icons/cart-alt.svg";
import { ReactComponent as ContactUsIcon } from "common/assets/icons/info-empty.svg";
import { ReactComponent as ConfigIcon } from "common/assets/icons/puzzle.svg";
import { useTranslation } from "react-i18next";
import { deleteCookie, getCookie } from "utils/cookie";

const Sidebar: React.FC = (): JSX.Element => {
	const { t, i18n } = useTranslation();
	const contentData = [
		{
			id: 1,
			name: t("sidebar.sliders"),
			link: routeConstants.slider.route,
			icon: (
				<SliderIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
		{
			id: 2,
			name: t("sidebar.categories"),
			link: routeConstants.category.route,
			icon: (
				<CategoryIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
		{
			id: 3,
			name: t("sidebar.products"),
			link: routeConstants.product.route,
			icon: (
				<ProductsIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
		{
			id: 4,
			name: t("sidebar.orders"),
			link: routeConstants.order.route,
			icon: (
				<OrdersIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
		{
			id: 5,
			name: t("sidebar.contact"),
			link: routeConstants.contact.route,
			icon: (
				<ContactUsIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
		{
			id: 6,
			name: t("sidebar.config"),
			link: routeConstants.config.route,
			icon: (
				<ConfigIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
			),
		},
	];

	const location = useLocation();
	const [activeRoute, setActiveRoute] = useState<string>("");

	const handleLanguageChange = (lng: string) => {
		i18n.changeLanguage(lng); // Change the language
		localStorage.setItem("i18nextLng", lng); // Update localStorage with the new language
		localStorage.setItem("languages", lng); // Update localStorage with the new language

		// A slight timeout to ensure the localStorage is set before reloading the page

		window.location.reload();
	};

	useEffect(() => {
		// Set activeRoute based on the current URL path
		const currentPath = location.pathname;
		const matchedRoute = contentData.find((item) => item.link === currentPath);
		if (matchedRoute) {
			setActiveRoute(matchedRoute.link);
		}
	}, [location]);

	const handleLogout = () => {
		deleteCookie("token");
		deleteCookie("username");
		deleteCookie("expirationDate");
	};

	const currentLanguage = localStorage.getItem("i18nextLng") || i18n.language;

	const username = getCookie("username");
	return (
		<div className="fixed top-0 left-0 h-screen w-[280px] bg-whiteColor border border-gray-200 overflow-hidden">
			<div className="px-6 py-8 border-b border-gray-200 cursor-default">
				<Logo className="h-8" />
			</div>
			<div className="px-4 py-10 overflow-y-auto max-h-[calc(100vh-200px)]">
				{contentData.map((item: any, idx: number) => (
					<Link
						to={item.link}
						key={idx}
						onClick={() => setActiveRoute(item.link)}
					>
						<div
							key={item.id}
							className={`grid gap-3 px-3 py-2 transition-all rounded-md cursor-pointer grid-cols-maxFr group duration-400 ${
								activeRoute === item.link ? "bg-hoverColor" : "bg-whiteColor"
							} hover:bg-gray-100`}
						>
							{item.icon &&
								React.cloneElement(item.icon, {
									className: `${
										activeRoute === item.link
											? "stroke-textColor"
											: "stroke-passiveTextColor group-hover:stroke-textColor"
									}`,
								})}
							<p
								className={`text-base ${
									activeRoute === item.link
										? "text-textColor"
										: "text-passiveTextColor"
								} group-hover:text-textColor`}
							>
								{item.name}
							</p>
						</div>
					</Link>
				))}
			</div>
			<div className="absolute bottom-0 w-[280px]">
				<div className="grid py-6 grid-cols-maxThree place-content-center place-items-center bg-whiteColor">
					{["en", "ru", "tk"].map((lang) => (
						<p
							key={lang}
							className={`px-2 text-base font-medium border-r last:border-none border-gray-200 cursor-pointer ${
								currentLanguage === lang
									? "text-textColor"
									: "text-passiveTextColor"
							}`}
							onClick={() => handleLanguageChange(lang)}
						>
							{lang === "en" && "English"}
							{lang === "ru" && "Русский"}
							{lang === "tk" && "Türkmen"}
						</p>
					))}
				</div>
				<div className="grid items-center gap-3 px-4 py-6 border-t border-gray-200 grid-cols-maxTwo place-content-between">
					<p className="font-semibold cursor-default text-passiveTextColor">
						{username}
					</p>
					<Link to={routeConstants.logout.route} onClick={handleLogout}>
						<LogoutIcon className="cursor-pointer" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
