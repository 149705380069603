export const setCookie = (name: string, value: string, hours: number): void => {
	const date = new Date();
	date.setTime(date.getTime() + hours * 60 * 60 * 1000);
	document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(";").shift();
};

export const deleteCookie = (name: string): void => {
	document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export function checkTokenValidity(): boolean {
	const token = getCookie("token");
	if (!token) return false;

	try {
		const payload = JSON.parse(atob(token.split(".")[1]));
		const expirationDate = new Date(payload.exp * 1000);
		return expirationDate > new Date();
	} catch (error) {
		return false;
	}
}
