import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRightIcon } from "common/assets/icons/fast-arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "common/assets/icons/fast-arrow-left.svg";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { motion } from "framer-motion";
import { CategoryItem } from "common/CategoryItem/CategoryItem";
import TabFilter, { FilterStatus } from "components/TabFilter/TabFilter";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import EmptyState from "common/EmptyState/EmptyState";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface CategoriesCardProps {
	data: any[];
	setSelectedSubcategory?: any;
	setSelectedSubSubcategory?: any;
	productPage?: boolean;
}

const CategoriesCard: React.FC<CategoriesCardProps> = ({
	data,
	setSelectedSubcategory,
	setSelectedSubSubcategory,
	productPage,
}) => {
	const { t } = useTranslation();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [filteredData, setFilteredData] = useState(data);
	const [selectedCategory, setSelectedCategory] = useState<any>(null);
	const [selectedCategoryName, setSelectedCategoryName] = useState<
		string | null
	>(null);

	const [rusValue, setRusValue] = useState("");
	const [engValue, setEngValue] = useState("");
	const [turkmenValue, setTurkmenValue] = useState("");

	const [isModalLoading, setIsModalLoading] = useState(false);

	useEffect(() => {
		setFilteredData(data);
	}, [data]);

	const handleFilterChange = (filter: FilterStatus) => {
		switch (filter) {
			case FilterStatus.All:
				setFilteredData([...data]); // Use spread to prevent data mutation
				break;
			case FilterStatus.Active:
				setFilteredData(
					data
						.map((category) => {
							let activeCategory = { ...category };
							activeCategory.subCategories =
								activeCategory.subCategories.filter((sub: any) => sub.isActive);
							return activeCategory;
						})
						.filter((category) => category.subCategories.length > 0)
				);
				break;
			case FilterStatus.Inactive:
				setFilteredData(
					data.flatMap((category) => {
						let inactiveSubs = category.subCategories.filter(
							(sub: any) => !sub.isActive
						);
						if (inactiveSubs.length > 0) {
							return {
								...category,
								subCategories: inactiveSubs,
							};
						}
						return [];
					})
				);
				break;
			default:
				setFilteredData([...data]);
		}
	};

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	const containerVariants = {
		open: {
			width: "349px",
			transition: {
				duration: 0.3,
			},
		},
		closed: {
			width: "69px",
			transition: {
				duration: 0.3,
			},
		},
	};
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Function to handle the click event
		const handleClickOutside = (event: any) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setDropdownOpen(false);
			}
		};

		// Add the event listener
		document.addEventListener("mousedown", handleClickOutside);

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	const [panelLabel, setPanelLabel] = useState("");
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [isManagePanelOpen, setManagePanelOpen] = useState(false);

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const handleDropdownSelect = (item: string) => {
		setPanelLabel(item); // Set the label
		setManagePanelOpen(true); // Open the panel
		setDropdownOpen(false);
	};

	const handleSave = () => {
		if (panelLabel === "Category") {
			saveCategory();
		} else if (panelLabel === "Subcategory") {
			if (!selectedCategory || !selectedCategory._id) {
				toast.error(t("categories.selectCategoryFirst"));
				return;
			}
			saveSubcategory();
		}
	};
	const saveCategory = async () => {
		if (!rusValue || !engValue || !turkmenValue) {
			toast.error(t("categories.fillAllFieldsError"));
			return;
		}

		setIsModalLoading(true);
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/categories`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: {
						en: engValue,
						ru: rusValue,
						tk: turkmenValue,
					},
					isActive: true,
				}),
			});

			const data = await response.json();

			if (response.ok) {
				toast.success(t("categories.categorySaveSuccess"));
				setManagePanelOpen(false);
				window.location.reload();
				setIsModalLoading(false);
			} else {
				console.error("Error adding category:", data.message);
				toast.error(t("categories.categorySaveError"));
				setIsModalLoading(false);
			}
		} catch (error: any) {
			console.error("Network or server error:", error.message);
			toast.error(t("categories.categorySaveError"));
			setIsModalLoading(false);
		}
	};

	const saveSubcategory = async () => {
		if (!rusValue || !engValue || !turkmenValue || !selectedCategory._id) {
			toast.error(t("categories.fillAllFieldsError"));
			return;
		}
		setIsModalLoading(true);

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/categories/${selectedCategory._id}/subcategories`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						name: {
							en: engValue,
							ru: rusValue,
							tk: turkmenValue,
						},
						isActive: true,
					}),
				}
			);

			const data = await response.json();

			if (response.ok) {
				toast.success(t("categories.subcategorySaveSuccess"));
				setManagePanelOpen(false);
				window.location.reload();
				setIsModalLoading(false);
			} else {
				console.error("Error adding subcategory:", data.message);
				toast.error(t("categories.subcategorySaveError"));
				setIsModalLoading(false);
			}
		} catch (error: any) {
			console.error("Network or server error:", error.message);
			toast.error(t("categories.subcategorySaveError"));
			setIsModalLoading(false);
		}
	};

	const categoryLabel = t("categories.category");

	return (
		<>
			{productPage ? (
				<motion.div
					className="bg-white border rounded-lg shadow-xs border-strokeColor"
					variants={containerVariants}
					initial={isCollapsed ? "closed" : "open"}
					animate={isCollapsed ? "closed" : "open"}
				>
					{isCollapsed ? (
						<div
							className="flex flex-col place-items-center place-content-end h-full min-h-[578px] p-6 gap-4 cursor-pointer"
							onClick={toggleCollapse}
						>
							<P
								size="p1"
								type="regular"
								className="w-full transform -rotate-90 cursor-pointer whitespace-nowrap text-passiveTextColor"
							>
								{t("categories.categoryList")}
							</P>
							<ArrowRightIcon className="w-6 h-6" />
						</div>
					) : (
						<div className="grid p-6 min-h-[578px] grid-rows-maxFr">
							<div className="grid gap-4 grid-rows-maxTwo">
								<div className="grid grid-cols-maxTwo place-content-between place-items-center">
									<Htag tag="h4" type="semibold">
										{t("categories.title")}
									</Htag>
								</div>
								<div className="grid gap-4">
									<TabFilter onFilterChange={handleFilterChange} />
									<div>
										{filteredData.length > 0 ? (
											filteredData.map((category, idx) => (
												<CategoryItem
													key={idx}
													category={category}
													onSelectSubcategory={setSelectedSubcategory}
													onSelectSubSubcategory={setSelectedSubSubcategory}
													productPage={productPage}
												/>
											))
										) : (
											<EmptyState
												title={t("categories.emptyCategory")}
												className="mt-[45%]"
											/>
										)}
									</div>
								</div>
							</div>
							<ArrowLeftIcon
								className="w-6 h-6 mt-12 cursor-pointer place-self-end"
								onClick={toggleCollapse}
							/>
						</div>
					)}
				</motion.div>
			) : (
				<motion.div
					className="bg-white border rounded-lg shadow-xs border-strokeColor"
					variants={containerVariants}
					initial={isCollapsed ? "closed" : "open"}
					animate={isCollapsed ? "closed" : "open"}
				>
					{isCollapsed ? (
						<div
							className="flex flex-col place-items-center place-content-end h-full min-h-[578px] p-6 gap-4 cursor-pointer"
							onClick={toggleCollapse}
						>
							<P
								size="p1"
								type="regular"
								className="w-full transform -rotate-90 cursor-pointer whitespace-nowrap text-passiveTextColor"
							>
								{t("categories.categoryList")}
							</P>
							<ArrowRightIcon className="w-6 h-6" />
						</div>
					) : (
						<div className="grid p-6 min-h-[578px] grid-rows-maxFr">
							<div className="grid gap-4 grid-rows-maxTwo">
								<div className="grid grid-cols-maxTwo place-content-between place-items-center">
									<Htag tag="h4" type="semibold">
										{t("categories.title")}
									</Htag>
									<div className="relative">
										<PlusIcon
											className="cursor-pointer stroke-passiveTextColor"
											onClick={toggleDropdown}
										/>
										{isDropdownOpen && (
											<div
												className="absolute z-50 right-0 bg-white border rounded-lg shadow-lg min-w-[150px]"
												ref={dropdownRef}
											>
												<div
													onClick={() => handleDropdownSelect("Category")}
													className="px-4 py-3 rounded-t-lg cursor-pointer hover:bg-hoverColor"
												>
													<P size="p3" type="medium">
														{t("categories.title")}
													</P>
												</div>
												<div
													onClick={() => handleDropdownSelect("Subcategory")}
													className="px-4 py-3 rounded-b-lg cursor-pointer hover:bg-hoverColor"
												>
													<P size="p3" type="medium" className="cursor-pointer">
														{t("categories.subcategory")}
													</P>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="grid gap-4">
									<TabFilter onFilterChange={handleFilterChange} />
									<div>
										{filteredData.length > 0 ? (
											filteredData.map((category, idx) => (
												<CategoryItem
													key={idx}
													category={category}
													onSelectSubcategory={setSelectedSubcategory}
												/>
											))
										) : (
											<EmptyState
												title={t("categories.emptyCategory")}
												className="mt-[45%]"
											/>
										)}
									</div>
								</div>
							</div>
							<ArrowLeftIcon
								className="w-6 h-6 mt-12 cursor-pointer place-self-end"
								onClick={toggleCollapse}
							/>
						</div>
					)}

					<ManagePanel
						isOpen={isManagePanelOpen}
						onClose={() => setManagePanelOpen(false)}
						label={`Add ${panelLabel}`}
						onSave={handleSave}
						onDiscard={() => {
							setManagePanelOpen(false);
						}}
						isLoading={isModalLoading}
					>
						{panelLabel === "Category" ? (
							<div className="grid gap-6">
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="rus"
									label="Русский"
									placeholder="Например: Women"
									value={rusValue}
									onChange={(e) => setRusValue(e.target.value)}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="eng"
									label="English"
									placeholder="For example: Women"
									value={engValue}
									onChange={(e) => setEngValue(e.target.value)}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="turkmen"
									label="Türkmen"
									placeholder="Meselem: Women"
									value={turkmenValue}
									onChange={(e) => setTurkmenValue(e.target.value)}
								/>
							</div>
						) : (
							<div className="grid gap-6">
								<Select
									onSelect={(value) => {
										const selectedCat = filteredData.find(
											(item) => item._id === value
										);
										if (selectedCat) {
											setSelectedCategory(selectedCat);
											setSelectedCategoryName(selectedCat.name.en);
										}
									}}
									defaultSelectLabel={
										selectedCategoryName || t("categories.selectCategory")
									}
									label={t("categories.category")}
								>
									{filteredData.map((item) => (
										<SelectItem value={item._id} key={item._id}>
											{item.name.en}
										</SelectItem>
									))}
								</Select>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="rus"
									label="Русский"
									placeholder="Например: Платья"
									value={rusValue}
									onChange={(e) => setRusValue(e.target.value)}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="eng"
									label="English"
									placeholder="For example: Dresses"
									value={engValue}
									onChange={(e) => setEngValue(e.target.value)}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="turkmen"
									label="Türkmen"
									placeholder="Meselem: Köýnekler"
									value={turkmenValue}
									onChange={(e) => setTurkmenValue(e.target.value)}
								/>
							</div>
						)}
					</ManagePanel>
				</motion.div>
			)}
		</>
	);
};

export default CategoriesCard;
