import React, { useState } from "react";
import { Input } from "common/Input/Input";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import login_image from "common/assets/images/login_image.png";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
import { serviceConfig } from "configs";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Spinner from "modules/loaders/Spinner/Spinner";
import { setCookie } from "utils/cookie";

const LoginPage: React.FC = () => {
	const { t } = useTranslation();
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const handleKeyPress = (e: any) => {
		if (e.key === "Enter") {
			handleLogin();
		}
	};

	const handleLogin = async () => {
		if (!username || !password) {
			toast.error(t("auth.validationError"));
			return;
		}
		setIsLoading(true);
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}${serviceConfig.routes.admin_login}`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ username, password }),
				}
			);
			const data = await response.json();
			if (response.ok) {
				toast.success(t("auth.successResponse"));
			}
			if (data.error) {
				toast.error(t("auth.errorResponse")); // Use translation if needed
				setIsLoading(false);
				return;
			}
			setCookie("username", data.data.username, 1);
			setCookie("token", data.data.token, 1); // Store for 1 hour, adjust as needed
			setCookie("expirationDate", data.data.expirationDate, 1);
			navigate("/");
		} catch (error) {
			console.error("Login failed", error);
			toast.error(t("auth.errorResponse"));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="grid grid-cols-2">
			<div>
				<div className="px-6 py-8 cursor-default">
					<Logo className="h-8" />
				</div>
				<div className="grid min-h-[85%] gap-10 place-content-center grid-rows-maxTwo bg-whiteColor">
					<Htag tag="h2" type="semibold" className="min-w-[25rem]">
						{t("auth.login")}
					</Htag>
					<div className="grid gap-6">
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="name"
							label={t("auth.username")}
							placeholder={t("placeholders.username")}
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							onKeyDown={handleKeyPress}
						/>

						<Input
							withlabel={true}
							appearance="default"
							type="password"
							name="name"
							label={t("auth.password")}
							placeholder={t("placeholders.password")}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							onKeyDown={handleKeyPress}
						/>

						{isLoading ? (
							<Spinner inModal={true} />
						) : (
							<Button appearance="black" onClick={handleLogin} width="w-full">
								{t("auth.login")}
							</Button>
						)}
					</div>
				</div>
			</div>
			<img src={login_image} alt="login_image" className="w-full h-screen" />
		</div>
	);
};

export default LoginPage;
